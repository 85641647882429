/*@media (min-width: 768px){*/
/*    .comboCheckout .container {*/
/*        width: 1140px;*/
/*    }*/
/*}*/

.vntCheckout {
    display: flex;
    flex-wrap: wrap;
    margin-top: 30px;
    padding: 0 15px 30px;
}
.vntCheckout__left {
    width: 100%;
}
.vntCheckout .heading2 {
    font-size: 20px;
    padding-bottom: 10px;
    color: #262626;
    margin: 0;
}
.vntCheckout__box {
    padding: 15px;
    margin-bottom: 15px;
    box-shadow: 0 2px 8px rgba(0,0,0,.15);
}
.vntCheckout__box>.pTitle {
    font-size: 16px;
    color: #262626;
    font-weight: 600;
    margin-bottom: 8px;
}
.listBank {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 16px;
}
.listBank__item {
    padding-right: 16px;
    padding-bottom: 16px;
}
.listBank__btn {
    width: 136px;
    height: 97px;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    background: #fff;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    transition: all .2s;
}
.listBank__item.active .listBank__btn {
    border-color: #FD8C13;
}
.bankInfo {
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    padding: 16px;
}
.bankInfo__list {
    padding-bottom: 16px;
}
.bankInfo__list li {
    display: flex;
    align-items: center;
    padding: 0 16px 6px;
}
.bankInfo__list li p, .listBank__btn p {
    font-size: 14px;
    color: #262626;
    margin-bottom: 0;
}
.bankInfo__list li p.p1 {
    width: 250px;
}
.bankInfo__list li p.p2 {
    width: calc(100% - 250px);
    padding-left: 17px;
    font-weight: 600;
}
.bankInfo__flex {
    display: flex;
}
.bankInfo__price {
    background-color: #f5f5f5;
    display: flex;
    align-items: center;
    padding: 0 16px;
    margin-right: 16px;
}
.bankInfo__price span.span1 {
    font-size: 14px;
    padding-right: 50px;
}
.bankInfo__price span.span2 {
    font-size: 20px;
    font-weight: 500;
    color: #096DD9;
}
.bankInfo__flex .btn {
    line-height: 1.5715;
    position: relative;
    display: inline-block;
    font-weight: 400;
    background-image: none;
    border: 1px solid #FD8C13;
    box-shadow: 0 2px 0 rgba(0,0,0,.015);
    cursor: pointer;
    transition: all .3s cubic-bezier(.645,.045,.355,1);
    user-select: none;
    touch-action: manipulation;
    background-color: #FD8C13;
    height: 40px;
    padding: 6.4px 15px;
    font-size: 16px;
    border-radius: 2px;
}
.bankInfo__flex a.btn  {
    color: #fff;
}
.bankInfo__note {
    font-size: 12px;
    color: #F5222D;
    margin-bottom: 0;
    margin-top: 8px;
}
.bankInfo__note strong {
    padding-right: 5px;
    font-weight: 600;
}

/* ======= page check out waiting confirm ========*/
.comboWaitingConfirm .vntBox {
    height: calc(100vh - 125px);
    display: flex;
    justify-content: center;
    margin-top: 100px;
}
.vntBox {
    margin: 15px;
    padding: 15px;
    position: relative;
}
.vntBox__checkout {
    text-align: center;
    width: 100%;
    margin: 0 auto;
}
.vntBox__checkout p.p1 {
    font-size: 24px;
    font-weight: 600    ;
    margin-top: 30px;
}
.vntBox__checkout p {
    color: #262626;
    font-size: 14px;
}

/* ===== end page check out waiting confirm =====*/