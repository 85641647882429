
.banner {
    padding: 0;
    position: relative;
}

.banner .header_hotline{
    font-size: 12px;
    color: #fff;
    background: #3BC6F5;
    border-radius: 30px;
    border: 2px solid #fff;
    padding: 5px;
    display: none;
    font-weight: 600;
    align-items: center;
    position: absolute;
    top: 8px;
    right: 8px;
}
.header_hotline svg{
    margin-right: 3px;
}
.header_hotline svg path{
    fill: #fff;
}

.DayPicker {
    /*box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);*/
    width: 100%;
}

.DayPicker:focus {
    outline: none;
}

.DayPicker-Month {
    width: 45%;
    margin: 1em 1em 0 0;
    display: block;
}
.DayPicker-Caption{
    display: inherit!important;
}
.DayPicker-WeekdaysRow, .DayPicker-Week{
    width: 100%;
    display: flex;
    justify-content: space-between;
}
.DayPicker-Day{
    width: calc(100% / 7);
    margin: 7px;
    padding: 7px 0;
    font-size: 14px;
    color: #262626;

}
.DayPicker-Weekday{
    width: calc(100% / 7);
    margin: 7px;
    padding: 7px 0;
    font-size: 14px;
    font-weight: 600;
    color: #262626;
}
.DayPicker-Body{
    display: inherit;
}
.DayPicker-Month:last-child {
    margin: 1em 0 0 1em;
}
.DayPicker-wrapper:focus{
    border: none;
    outline: none;
}
.DayPicker-Weekdays{
    display: inherit;
}
.DayPicker-Day--outside{
    background: transparent!important;
}

@media (max-width: 768px) {
    .DayPicker {
        box-shadow: none;
        /*border-bottom: 1px solid #E8E8E8;*/
        border-top: 1px solid #E8E8E8;

    }

    .DayPicker-Month {
        width: 100%;
    }
    .DayPicker-Day{
        margin: 5px;

    }
}

.DayPicker-Day {
    border-radius: 5px;
    transition: 0.4s;
    outline: none;
}

.DayPicker-Day--disabled {
    cursor: not-allowed;
    text-decoration: line-through;
}

.DayPicker-Day--today {
    font-weight: 400;
}

.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
    color: #FD8C13;
    background: none;
}

.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside):hover {
    /*background-color: #FD8C13;*/
}

.DayPicker:not(.DayPicker--interactionDisabled) .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):hover {
    color: #FD8C13;
    background: transparent;

}

.DayPicker-Day--disabled:focus {
    outline: none;
}

.DayPicker:not(.DayPicker--interactionDisabled) .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):focus {
    outline: none;
}

.DayPicker-Caption {
    text-align: center;
    border-bottom: 1px solid #E8E8E8;
    padding-bottom: 1em;
}

.DayPicker-Caption > div {
    font-size: 14px;
    font-weight: 600;
    color: #262626;
}
.DayPicker-Month{

}

.DayPicker-Day--disabled {
    color: #DCE0E0;
}

/*.======DayPicker======*/
.ant-modal-mask-hidden {
    display: none;
}

.ant-modal-wrap {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1000;
    overflow: auto;
    outline: 0;
    -webkit-overflow-scrolling: touch;
}

.ant-modal-mask {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1000;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.45);
    filter: alpha(opacity=50);
}

.modal-map {
    width: 100% !important;
    height: 100%;
}

.ant-modal.modal-map {
    top: 0px;
    padding-bottom: 0;
}

.ant-modal {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    margin: 0;
    color: rgba(0, 0, 0, 0.65);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    -webkit-font-feature-settings: 'tnum';
    font-feature-settings: 'tnum', "tnum";
    position: relative;
    top: 0px;
    width: auto;
    margin: 0 auto;
    padding: 0 0 24px;
    pointer-events: none;
}

.modal-map .ant-modal-content {
    height: 100%;
}

.ant-modal-content {
    position: relative;
    background-color: #fff;
    background-clip: padding-box;
    border: 0;
    border-radius: 2px;
    -webkit-box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
    box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
    pointer-events: auto;
}

.ant-modal-close {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 10;
    padding: 0;
    color: rgba(0, 0, 0, 0.45);
    font-weight: 700;
    line-height: 1;
    text-decoration: none;
    background: transparent;
    border: 0;
    outline: 0;
    cursor: pointer;
    -webkit-transition: color 0.3s;
    transition: color 0.3s;
}

.ant-modal-header {
    padding: 16px 24px;
    color: rgba(0, 0, 0, 0.65);
    background: #fff;
    /*border-bottom: 1px solid #f0f0f0;*/
    border-radius: 2px 2px 0 0;
}

.ant-modal-title {
    margin: 0;
    color: rgba(0, 0, 0, 0.85);
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    word-wrap: break-word;
}

.modal-map .ant-modal-body {
    height: calc(100% - 55px);
    padding: 0;
}

.ant-modal-body {
    padding: 24px;
    font-size: 14px;
    line-height: 1.5715;
    word-wrap: break-word;
}

.modal-map .ant-modal-body iframe {
    width: 100%;
    height: 100%;
}

.ant-modal-close-x {
    display: block;
    width: 56px;
    height: 56px;
    font-size: 16px;
    font-style: normal;
    line-height: 56px;
    text-align: center;
    text-transform: none;
    text-rendering: auto;
}

.ant-modal-wrap.modal-gallery .ant-modal {
    top: 0px;
    width: 100% !important;
    height: 100%;
}

.ant-modal-wrap.modal-gallery .ant-modal-content {
    height: 100%;
}

.ant-modal-content {
    position: relative;
    background-color: #fff;
    background-clip: padding-box;
    border: 0;
    border-radius: 2px;
    -webkit-box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
    box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
    pointer-events: auto;
}

.ant-modal-wrap.modal-gallery .ant-modal-body {
    padding: 0;
    height: calc(100% - 55px);
}

.ant-modal-wrap.modal-gallery .image-gallery, .ant-modal-wrap.modal-gallery .image-gallery-content, .ant-modal-wrap.modal-gallery .image-gallery-slide > div, .ant-modal-wrap.modal-gallery .image-gallery-slides, .ant-modal-wrap.modal-gallery .image-gallery-swipe {
    height: 100%;
}

.image-gallery {
    -webkit-user-select: none;
    user-select: none;
    -webkit-tap-highlight-color: transparent;
    position: relative;
}

.image-gallery-content {
    position: relative;
    line-height: 0;
    top: 0;
}

.ant-modal-wrap.modal-gallery .image-gallery-slide-wrapper {
    height: calc(100% - 95px);
}

.image-gallery-slide-wrapper {
    position: relative;
}

.ant-modal-wrap.modal-gallery .image-gallery-thumbnails-wrapper {
    height: 95px;
}

.image-gallery-thumbnails-wrapper {
    position: relative;
}

.ant-modal-wrap.modal-gallery .image-gallery-icon {
    filter: none;
}

.ant-modal-wrap.modal-gallery .image-gallery-left-nav .image-gallery-svg, .ant-modal-wrap.modal-gallery .image-gallery-right-nav .image-gallery-svg {
    height: 50px;
    width: 20px;
    stroke: #FA8C16;
}

.ant-modal-wrap.modal-gallery .image-gallery, .ant-modal-wrap.modal-gallery .image-gallery-content, .ant-modal-wrap.modal-gallery .image-gallery-slide > div, .ant-modal-wrap.modal-gallery .image-gallery-slides, .ant-modal-wrap.modal-gallery .image-gallery-swipe {
    height: 100%;
}

.image-gallery-thumbnails {
    overflow: hidden;
    padding: 5px 0;
}

.image-gallery-thumbnails .image-gallery-thumbnails-container {
    cursor: pointer;
    text-align: center;
    transition: transform .45s ease-out;
    white-space: nowrap;
}

.ant-modal-wrap.modal-gallery .image-gallery-thumbnail {
    height: 69px;
    width: 69px;
    border-radius: 3px;
    border: 3px solid transparent;
}

.image-gallery-thumbnail {
    display: inline-block;
    border: 4px solid transparent;
    transition: border .3s ease-out;
    width: 100px;
    background: transparent;
    padding: 0;
}

.image-gallery-slide {
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
}

.ant-modal-wrap.modal-gallery .image-gallery-slide .image-gallery-image {
    text-align: center;
    height: 100%;
    object-fit: cover;
}

.ant-modal-wrap.modal-gallery .image-gallery-thumbnail .image-gallery-thumbnail-inner {
    width: 100%;
    height: 100%;
}

.ant-modal-wrap.modal-gallery .image-gallery-thumbnail .image-gallery-thumbnail-inner img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    border-radius: 3px;
}

.ant-modal-wrap.modal-gallery .image-gallery-thumbnail.active, .ant-modal-wrap.modal-gallery .image-gallery-thumbnail:focus, .ant-modal-wrap.modal-gallery .image-gallery-thumbnail:hover {
    border: 3px solid #FD8C13;
}

[data-tip] {
    position: relative;
    z-index: 10;
}

[data-tip]:after {
    content: attr(data-tip);
    top: calc(100% + 3px);
    left: 0;
    z-index: 1;
    padding: 5px 10px;
    display: block;
    color: #fff;
    font-size: 14px;
    border-radius: 3px;
}

[data-tip]:before {
    content: '';
    left: 9px;
    top: 100%;
    z-index: 2;
    border-top: 1px solid #d0011b;
    border-left: 1px solid #d0011b;
    width: 7px;
    height: 7px;
    transform: rotate(45deg);
}

[data-tip]:after, [data-tip]:before {
    position: absolute;
    background-color: #d0011b;
}

.vnt-disable {
    pointer-events: none;
}

.vnt-disable * {
    pointer-events: none;
    opacity: .4;
}

.modal-backdrop {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1040;
    background-color: #000;
}

.modal-backdrop.in, .modal-backdrop.show {
    opacity: .7;
}

.modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto;
}

.modal-dialog {
    width: 600px;
    margin: 50px auto;
    box-shadow: 0 1px 2px rgba(0, 0, 0, .1);
    border-radius: 6px;
    background-color: #fff;
}

.modal.in .modal-dialog, .modal.show .modal-dialog {
    -webkit-transform: translate(0);
    transform: translate(0);
}

.modal-content {
    position: relative;
}

.modal {
    display: none;
    position: fixed;
    z-index: 1050;
    -webkit-overflow-scrolling: touch;
    outline: 0;
}

.modal, .vnt-loading {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.modal-dialog.modal-noti .modal-header {
    padding: 30px 15px 15px;
}

.header-popup__title, .modal-dialog.modal-noti .modal-body, .modal-title, .rating-point__numb p, .title-1 {
    text-align: center;
}

.modal-body, .modal-footer {
    padding: 15px;
}

.modal-dialog.modal-noti .modal-footer {
    text-align: center;
    border-top: 1px solid #eee;
}

.modal-close {
    position: absolute;
    top: -15px;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    z-index: 1;
    padding: 0;
    cursor: pointer;
    background: 0 0;
    border: none;
    display: flex;
}

.modal-dialog.modal-noti .modal-footer .btn_round {
    width: 125px;
}

.btn_round {
    position: relative;
    display: inline-block;
    padding: 10px 15px;
    font-weight: 600;
    color: #212529;
    white-space: nowrap;
    touch-action: manipulation;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border: none;
    border-radius: 6px;
    background-color: #fdb913;
}

.btn.btn_disable {
    pointer-events: none;
    background: #ccc;
    border: 1px solid transparent;
}

.react-datepicker-wrapper {
    display: block;
    padding: 0;
    border: 0;
}

.loadingBox {
    position: fixed;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
    z-index: 100;
    background-color: rgb(255, 255, 255) !important;
}

.loadingBox__slide .loadingBox__slide__item .p2 {
    font-style: italic;
    line-height: 1.5em;
    text-align: center;
}

@media (max-width: 768px) {
    .ant-modal, .ant-modal-wrap.modal-gallery .ant-modal {
        top: 0px;
        height: 100%;
    }

    .modal-dialog {
        width: 90%;
    }

    .ant-modal.modal-map {
        width: 100% !important;
    }

    .DayPicker-Month {
        width: 100%;
        margin: 1em 0 0 0;
    }

    .DayPicker-Month:last-child {
        margin: 1em 0 0 0;
    }

    .ant-modal {
        padding-bottom: 0;
        max-width: 100% !important;
    }

    .modal-map .ant-modal-content, .ant-modal-wrap.modal-gallery .ant-modal-content {
        height: 100%;
    }

    .ant-modal-wrap.modal-gallery .image-gallery-slide {
        background: 0 0;
        height: 100%;
    }

    .step5, .step4 {
        padding-bottom: 60px;
    }
}

body .icon-check {
    z-index: 1;
}

body .passengerInfo__row.row-2 .form-group:nth-child(1) {
    width: 80px;
}

.formPay__option .radio__icon.image-coupon img {
    height: 25px;
    margin-left: 10px;
}


.hotelReview__info .title-hotel h2 {
    border-bottom: 1px solid #FA8C16;
    padding-bottom: 5px
}

.comboFligt__cont .comboFlight__item:last-child {
    border-bottom: none;
    margin-bottom: 0;
    padding-bottom: 0;
}

.comboFligt__cont .comboFlight__item {
    padding-bottom: 5px;
}

.comboFligt__cont .comboFlight__item ul {
    padding-bottom: 0;
    margin-bottom: 0;
}

.slick-arrow {
    display: none !important;
}

@media (max-width: 991px) {
    .achievement__item:nth-child(1) {
        width: 100%;
    }

    .achievement__item:nth-child(n+2) {
        width: 50%;
    }

    .achievement {
        display: flex;
        flex-wrap: wrap;
    }
}

.rateStar svg {
    width: 15px !important;
    height: 15px !important;
    margin-bottom: 5px;
}

.rateStar .star-container {
    padding-right: 3px !important;
    padding-left: 0 !important;
}

.achievement__item .title {
    font-weight: bold;
}

.input-group-coupon {
    display: flex;
}

.form-coupon__input label {
    font-weight: 600;
}

.input-group-coupon .input-group-btn .btn {
    height: 40px;
    color: #fff;
    background: #ff8916;
    border-radius: 0 4px 4px 0;
    transform: translateX(-2px);
}

.form-coupon__input .ant-form-item {
    margin-bottom: 0;
    width: 100%;
}

.payment__method {
    padding: 15px 15px 0 15px;
    border: 1px solid #f5f5f5;

}

.payment__method .formPay {
    padding: 15px 0 0 0;
}

.payment__method .btnGroup {
    margin-top: 0;
}

.payment__method .ant-form-item-has-success .ant-form-item-explain div {
    color: #91bb15;
}

.payment__method .ant-form-item-explain {
    margin-top: 6px;
}

.size-13 {
    font-size: 13px !important;
}

.footer {
    min-height: 70px;
}

.passengerInfo .headerPopup {
    background-color: #ff8917;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 15px;
    height: 50px;
}

@media (min-width: 768px) {
    .ant-picker-footer .headerPopup {
        display: none;
    }
}

@media (max-width: 767px) {
    .ant-picker-active-bar {
        display: none
    }

    .ant-picker-dropdown {
        position: fixed !important;
        top: 0 !important;
        left: 0 !important;
        right: 0 !important;
        bottom: 0 !important;
        padding: 0
    }

    .ant-picker-range-wrapper {
        min-width: initial !important;
        width: 100%;
        height: 100%
    }

    .ant-picker-range-arrow {
        display: none !important
    }

    .ant-picker-panel-container {
        box-shadow: none;
        width: 100%;
        height: 100%;
        display: flex;
        flex-wrap: wrap
    }

    .ant-picker-panel-container .ant-picker-panels, .ant-picker-panel-container > .ant-picker-panel > .ant-picker-date-panel {
        height: calc(100% - 50px);
        order: 2;
        overflow-y: auto;
        -webkit-overflow-scrolling: touch
    }

    .ant-picker-panel-container .ant-picker-panels, .ant-picker-panel-container > .ant-picker-panel {
        flex-wrap: wrap
    }

    .ant-picker-panel-container .ant-picker-footer {
        order: 1;
        height: 50px
    }

    .ant-picker-panel-container .ant-picker-footer .ant-picker-footer-extra {
        padding: 0
    }

    .ant-picker-date-panel, .ant-picker-date-panel .ant-picker-content, .ant-picker-decade-panel, .ant-picker-month-panel, .ant-picker-panel, .ant-picker-quarter-panel, .ant-picker-time-panel, .ant-picker-week-panel, .ant-picker-year-panel {
        width: 100%
    }

    .ant-picker-cell .ant-picker-cell-inner {
        min-width: 36px;
        height: 36px;
        line-height: 36px
    }

    .ant-picker-cell::before {
        height: 36px
    }

    .ant-picker-cell {
        padding: 5px 0
    }

    .ant-picker-large .ant-picker-input > input {
        font-size: 15px
    }

    .ant-picker-footer .headerPopup {
        background-color: #ff8916;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 15px;
        height: 50px;
    }

    .ant-picker-footer .headerPopup p {
        font-weight: 600;
        color: #fff;
        margin-bottom: 0;
    }

    .headerPopup__close {
        padding: 10px 0 10px 15px;

    }

    .ant-picker-today-btn.ant-picker-today-btn-disabled {
        display: none;
    }
}

.background-white {
    background: #ffffff !important;
}

@media (max-width: 767px) {
    .ant-modal{
        margin-top:0px;
    }
    .passengerInfo .react-datepicker-popper {
        position: fixed !important;
        bottom: 0 !important;
        right: 0 !important;
        transform: none !important;
        left: 0 !important;
        top: 0 !important;
        transition: 0.4s;
        z-index: 99;
        margin: 0 !important;
    }

    .passengerInfo .react-datepicker-popper > div {
        width: 100%;
        height: 100%;
    }

    .passengerInfo .react-datepicker-popper .react-datepicker, .react-datepicker__month-container {
        width: 100%;
        height: 100%;
        border-radius: inherit !important;
        border: none !important;
    }

    .passengerInfo .react-datepicker__week .react-datepicker__day, .passengerInfo .react-datepicker__day-names .react-datepicker__day-name {
        width: 40px;
        height: 40px;
        line-height: 40px;
    }

    .passengerInfo .react-datepicker__day-names .react-datepicker__day-name {
        color: #fff !important;
    }

    .react-datepicker__week .react-datepicker__day {
        height: 40px;
        line-height: 40px;
    }

    .passengerInfo .react-datepicker__current-month.react-datepicker__current-month--hasYearDropdown.react-datepicker__current-month--hasMonthDropdown {
        padding: 10px 0;
        color: #fff;
    }

    .passengerInfo .react-datepicker__header__dropdown.react-datepicker__header__dropdown--select {
        padding-bottom: 10px;
    }

    .passengerInfo .react-datepicker__day-names, .react-datepicker__week {
        padding: 5px 0;
    }

    .passengerInfo .react-datepicker__day--keyboard-selected, .passengerInfo .react-datepicker__day--selected {
        background: #ff8916 !important;
    }

    .passengerInfo .react-datepicker__month-select, .react-datepicker__year-select {
        height: 30px;
        margin-right: 10px;
        padding: 5px;
    }

    .passengerInfo .react-datepicker__header {
        background: #ff8916 !important;
        border-radius: initial;
    }

    .passengerInfo .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle, .react-datepicker__year-read-view--down-arrow, .react-datepicker__month-read-view--down-arrow, .react-datepicker__month-year-read-view--down-arrow, .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle::before, .react-datepicker__year-read-view--down-arrow::before, .react-datepicker__month-read-view--down-arrow::before, .react-datepicker__month-year-read-view--down-arrow::before {
        display: none;
    }

    .passengerInfo .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle, .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before {
        display: none;
    }
}

.passengerInfo .react-datepicker__day--keyboard-selected, .passengerInfo .react-datepicker__day--selected {
    background: #ff8916 !important;
}

.passengerInfo .react-datepicker__header {
    background: #ff8916 !important;
}

.passengerInfo .react-datepicker__current-month.react-datepicker__current-month--hasYearDropdown.react-datepicker__current-month--hasMonthDropdown {
    color: #fff;
}

.passengerInfo .react-datepicker__day-names .react-datepicker__day-name {
    color: #fff !important;
}

.passengerInfo .react-datepicker__month-select, .react-datepicker__year-select {
    color: #595959;
}

.passengerInfo .react-datepicker__navigation--previous {
    border-right-color: #fff !important;
}

.passengerInfo .react-datepicker__navigation--next {
    border-left-color: #fff !important;
}

.passengerInfo .react-datepicker {
    border: none !important;
    box-shadow: 0 -2px 6px rgba(0, 0, 0, .15);
}

.passengerInfo .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle, .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before {
    border-bottom-color: #ff8916 !important;
    transform: translateY(1px);
}
.form__taxCode{
    width: 100%;
    text-align: left;
}
.form__taxCode .form__title{
    margin-bottom: 10px;
}
.form__taxCode .input__search{
    display: flex;
}
.form__taxCode .input__search   input{
    margin-right: 10px;
}


.form__taxCode .form__title span{
    border-bottom: 1px solid #ff8916;
    padding: 5px 0;
    display: inline-block;
}
.form__taxCode .ant-form-item {
    margin-bottom: 10px;
}
.form__taxCode .ant-form-item-explain{
    min-height: 0;
}
/*.ant-modal-footer{*/
/*    display: none;*/
/*}*/
.ant-modal-body{
    padding: 15px;
}
.ant-modal{
    margin-top:50px;
}

.btn__request__invoice{
    background: #ff8916;
    border: 1px solid #ff8916;
}
.btn__orange{
    background: #ff8916;
    border: 1px solid #ff8916;
}
.btn__orange:hover{
    background: #ff8916;
    border: 1px solid #ff8916;
    opacity: 0.8;
}
.btn__orange:focus{
    background: #ff8916;
    border: 1px solid #ff8916;
}
.bookingCode{
    background: #fff8e8;
    border: 2px solid #ff8916
}
.bookingCode .bookingCode__title{
    color: #262626;
}

.ant-collapse > .ant-collapse-item:last-child, .ant-collapse > .ant-collapse-item:last-child > .ant-collapse-header{
    padding-bottom: 10px;
    padding-top: 10px;
}
.ant-collapse-item{
    margin-bottom: 15px;
}
.ant-collapse{
    background-color: #ffffff;
}
.ant-collapse-header{
    font-weight: 600;
}
.ant-collapse > .ant-collapse-item-active{
    padding-bottom: 0;
}
.ant-collapse > .ant-collapse-item-active .ant-collapse-header{
    padding-bottom: 20px!important;
}
.footer__top__item p .anticon{
    width: 20px;
    margin-right: 10px;
}
.footer__top__item ul li a{
    color: #595959;
    margin: 0;
}
.footer__top__item ul li a:hover{
    text-decoration: underline;
}
.footer__top__item p a:hover{
    text-decoration: underline;
}

.footer__top__item p a{

}
.header__logo img{
    max-height: 50px;
    width: 100px;
    object-fit: contain;
}
.d-flex{
    display: flex;
}
.footer__middle{
    justify-content: flex-end;
    padding: 0;
    border: none;
}
.footer__top{
    border: none;
    padding-bottom: 0;
}
.footer__bocongthuong a{
    width: 50%;
    margin-left: 10px;
}
.footer__middle__image{
    width: 43%;
}
.footer__bocongthuong{
    width: 100%;
}
.container-combo{
    width: 850px!important;
}
.header .btn_login{
    width: 125px;
}
@media (max-width: 767px) {
    .banner .header_hotline{
        display: flex;
    }
    .footer__middle__image{
        width: 100%;
    }
    .footer__bocongthuong a{
        margin-left: 0px;
    }
    .container-combo{
        width: 100%!important;
    }
    .header .btn_login{
       display: none;
    }
    .header__menu{
        display: none;
    }
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0;
}

.form-box-search{
    min-height: auto;
    padding-bottom: 15px;
}
/*start color*/
.gray1{
    color: #595959;
}
/* end color*/

.box-shadow-primary{
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
}

.w-100{
    width: 100%;
}
/* start flex */
.flexGroup{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.flexWrap{
    flex-wrap: wrap;
}
/* end flex */

.d-block{
    display: block;
}