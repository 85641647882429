label,
p {
  margin-bottom: 5px;
}
ins,
mark {
  background-color: #ff9;
  color: #000;
}
button,
img,
input,
select,
textarea {
  vertical-align: middle;
}
a,
button {
  padding: 0;
  background: 0 0;
  cursor: pointer;
}
a,
ins {
  text-decoration: none;
}
a,
button,
select {
  cursor: pointer;
}
body,
button,
input,
select,
textarea {
  font-family: "Open Sans";
}
.dropdown.dropdown-open .dropdown-menu,
.dropdown.show .dropdown-menu,
article,
aside,
button svg,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
.dropdown,
.dropdown-toggle {
  position: relative;
}
.form-control:focus,
a:focus,
button:focus {
  outline: 0;
}
.slick-dots,
ul {
  list-style: none;
}
.header__hotline svg path,
.listIcon li svg path {
  fill: #389e0d;
}
.btn.btn_disabled,
.radio.disabled label,
button.disabled {
  pointer-events: none;
}
.italic,
mark {
  font-style: italic;
}
@font-face {
  font-family: "Open Sans";
  src: url(https://statics.vntrip.vn/fonts/OpenSans/Regular/OpenSans-Regular.woff?v=1.101)
    format("woff");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Open Sans";
  src: url(https://statics.vntrip.vn/fonts/OpenSans/Italic/OpenSans-Italic.woff?v=1.101)
    format("woff");
  font-weight: 400;
  font-style: italic;
}
@font-face {
  font-family: "Open Sans";
  src: url(https://statics.vntrip.vn/fonts/OpenSans/SemiBold/OpenSans-SemiBold.woff?v=1.101)
    format("woff");
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: "Open Sans";
  src: url(https://statics.vntrip.vn/fonts/OpenSans/Bold/OpenSans-Bold.woff?v=1.101)
    format("woff");
  font-weight: 700;
  font-style: normal;
}
* {
  margin: 0;
  padding: 0;
}
*,
:after,
:before {
  box-sizing: border-box;
}
html {
  -webkit-tap-highlight-color: transparent;
}
blockquote,
q {
  quotes: none;
}
blockquote:after,
blockquote:before,
q:after,
q:before {
  content: "";
}
img {
  max-width: 100%;
}
label {
  display: inline-block;
}
h1,
h2,
h3,
h4,
h5 {
  font-weight: 600;
}
mark,
strong {
  font-weight: 700;
}
button {
  border: none;
}
textarea {
  resize: none;
}
input::-webkit-input-placeholder {
  color: #bcbcbc;
}
input::-moz-placeholder {
  color: #bcbcbc;
}
input:-ms-input-placeholder {
  color: #bcbcbc;
}
input:-moz-placeholder {
  color: #bcbcbc;
}
del {
  text-decoration: line-through;
}
abbr[title],
dfn[title] {
  border-bottom: 1px dotted;
  cursor: help;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
.span-blue {
  color: #389e0d;
  font-weight: 600;
}
.span-red {
  color: #cf1322;
  font-weight: 600;
}
a {
  margin: 0 0 0 5px;
  font-size: 100%;
  vertical-align: baseline;
  color: #438dc4;
}
.container,
.container-combo {
  margin-right: auto;
  margin-left: auto;
  padding-left: 15px;
  padding-right: 15px;
}
.btn,
.comboInfo__detail li p span,
.comboInfo__detail li p:first-child svg,
.form-box-search .box-search-item .caret,
.header__hotline span,
.header__hotline svg {
  vertical-align: middle;
}
a:hover {
  opacity: 0.8;
}
body {
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
  color: #262626;
  background-color: #fff;
  -webkit-font-smoothing: antialiased;
}
@media (min-width: 768px) {
  .container {
    width: 750px;
  }
}
@media (min-width: 992px) {
  .container {
    width: 970px;
  }
}
@media (min-width: 1200px) {
  .container {
    width: 1170px;
  }
}
@media (min-width: 1920px) {
  .container {
    width: 1440px;
  }
}
@media (min-width: 768px) {
  .container-combo {
    width: 750px;
  }
}
.dropdown.dropdown_form .dropdown-item {
  cursor: auto;
}
.dropdown.dropdown_form .dropdown-item:hover {
  background-color: initial;
  color: initial;
}
.dropdown.dropdown_form .input-inline {
  margin-bottom: 13px;
}
.dropdown.dropdown_col .dropdown-menu {
  width: 364px;
  left: auto;
  right: 0;
  max-height: initial;
  overflow: hidden;
}
button.disabled {
  opacity: 0.3;
}
.dropdown-toggle {
  color: #262626;
  border: 1px solid rgba(0, 0, 0, 0.15);
  background: #fff;
  border-radius: 2px;
  width: 100%;
  height: 32px;
  text-align: left;
  padding: 5px 15px;
  font-size: 14px;
}
.dropdown-toggle > svg {
  position: absolute;
  top: 50%;
  right: 15px;
  transform: translateY(-50%);
}
.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 10;
  width: 100%;
  background-color: #fff;
  border-radius: 3px;
  display: none;
  transform: none;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}
.dropdown-menu::-webkit-scrollbar {
  width: 4px;
}
.dropdown-menu::-webkit-scrollbar-track {
  background-color: #f2f2f2;
  border-radius: 16px;
}
.dropdown-menu::-webkit-scrollbar-thumb {
  background: #bcbcbc;
  border-radius: 16px;
}
.dropdown-menu::-webkit-scrollbar-thumb:hover {
  background: #bcbcbc;
}
.dropdown-item {
  padding: 7px 15px;
  color: #262626;
  cursor: pointer;
  transition: all 0.2s;
  font-size: 14px;
}
.dropdown-item.active,
.dropdown-item:hover {
  background-color: #fff7e6;
  color: #fd8c13;
}
.dropdownGroup {
  display: flex;
}
.dropdownGroup .dropdown + .dropdown {
  margin-left: 8px;
}
.vnt-radio input[type="radio"] {
  display: none;
}
.vnt-radio label {
  position: relative;
  cursor: pointer;
  margin-bottom: 0;
  padding-left: 25px;
  height: 16px;
  line-height: 15px;
  font-size: 12px;
  font-weight: 400;
}
.btn,
.loadingBox__slide__text p.p1,
.loadingBox__wait p.p1,
.semibold,
.title1 {
  font-weight: 600;
}
.vnt-radio input[type="radio"] + label:before {
  content: "";
  width: 16px;
  height: 16px;
  line-height: 15px;
  position: absolute;
  left: 0;
  top: 0;
  display: inline-block;
  text-align: center;
  border: 1px solid #ccc;
  border-radius: 50%;
  background-color: #fff;
  box-shadow: inset 0 2px 3px 0 rgba(0, 0, 0, 0.2);
}
.vnt-radio input[type="radio"]:checked + label:before {
  width: 15px;
  height: 15px;
  border: 4px solid #fff;
  box-shadow: 0 0 0 1px #fa8c16;
  background: #fa8c16;
}
.slick-dots {
  position: absolute;
  bottom: 15px;
  left: 0;
  right: 0;
  display: block;
  text-align: center;
  padding: 0;
  margin: 0;
  width: 100%;
}
.slick-dots li {
  position: relative;
  display: inline-block;
  margin: 0 5px;
  padding: 0;
  cursor: pointer;
}
.slick-dots li button {
  border: 0;
  display: block;
  outline: 0;
  font-size: 0;
  width: 10px;
  height: 10px;
  background: #fff;
  cursor: pointer;
  border-radius: 50%;
}
@media (min-width: 768px) {
  .slick-dots {
    bottom: 1vw;
  }
  .slick-dots li {
    margin: 0 0.4vw;
  }
  .slick-dots li button {
    width: 0.7vw;
    height: 0.7vw;
  }
}
.slick-dots li.slick-active button {
  background: #f79320;
}
@-webkit-keyframes slideIcon {
  20% {
    margin-left: 0;
  }
  40% {
    margin-left: -255px;
  }
  60% {
    margin-left: -510px;
  }
  80% {
    margin-left: -765px;
  }
  90% {
    margin-left: -1020px;
  }
}
@-webkit-keyframes slideText {
  20% {
    margin-left: 0;
  }
  40% {
    margin-left: -600px;
  }
  60% {
    margin-left: -1200px;
  }
  80% {
    margin-left: -1800px;
  }
  90% {
    margin-left: -2400px;
  }
}
@keyframes textFadeOut {
  to {
    opacity: 0;
    transform: translateY(-50px);
  }
}
@keyframes textFadeIn {
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
@keyframes opaqTransition {
  25%,
  3% {
    transform: translateX(0);
  }
  100%,
  28% {
    transform: translateX(-600px);
  }
}
.loadingBox {
  width: 100vw;
  height: 100vh;
  background-color: #fff;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 15px;
}
.loadingBox__dot {
  text-align: center;
}
.loadingBox__dot p {
  color: #2e3a59;
  font-size: 14px;
  margin-top: 15px;
}
.loadingBox__progress {
  margin-bottom: 30px;
}
.loadingBox__progress p {
  font-size: 16px;
  color: #595959;
}
.loadingBox__progress progress {
  height: 3px;
  background: #d9d9d9;
  border-radius: 30px;
}
.loadingBox__progress progress::-webkit-progress-value {
  background: #1890ff;
  transition: width 0.5s;
}
.loadingBox__progress progress::-moz-progress-bar {
  background: #1890ff;
  transition: width 0.5s;
}
.loadingBox__progress progress[value]::-ms-fill {
  background: #1890ff;
  transition: width 0.5s;
}
.loadingBox__item {
  width: 100%;
  animation: opacityShow 0.3s;
}
@media (min-width: 768px) {
  .loadingBox__progress {
    margin-bottom: 100px;
  }
  .loadingBox__item {
    width: 75%;
    margin: 0 auto;
  }
}
.loadingBox__step2 {
  animation: fadeShow 0.5s;
  height: 100%;
  padding-top: 10%;
}
.loadingBox__group1 {
  animation: opacityShow 0.5s;
}
.loadingBox__group2 {
  animation: opacityShow 1s;
}
.loadingBox__wait {
  text-align: center;
  margin-top: 50px;
}
.loadingBox__wait p {
  color: #222b45;
}
.loadingBox__wait p.p1 {
  font-size: 24px;
}
.loadingBox__text {
  text-align: center;
  position: relative;
  min-height: 22px;
  overflow: hidden;
}
.loadingBox__text p {
  width: 100%;
  text-align: center;
}
.loadingBox__text p.p2 {
  margin-bottom: 0;
  font-size: 16px;
  position: absolute;
}
.loadingBox__text p.p2.animation1 {
  transform: translateY(0);
  animation: textFadeOut 0.5s ease-in 5s forwards;
}
.loadingBox__text p.p2.animation2 {
  opacity: 0;
  transform: translateY(50px);
  animation: textFadeIn 0.5s ease-in 5s forwards;
}
.loadingBox__icon {
  width: 255px;
  height: 195px;
  overflow: hidden;
  margin: 0 auto;
}
.loadingBox__icon__row {
  width: 1275px;
  animation: slideIcon 12s ease infinite;
}
.loadingBox__icon__item {
  width: 255px;
  height: 195px;
  cursor: auto;
  float: left;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 30px;
}
.loadingBox__icon__item img {
  width: 100%;
}
.loadingBox__slide {
  margin: auto;
  overflow: hidden;
  position: relative;
  width: 100%;
  height: 350px;
}
@media (min-width: 768px) {
  .loadingBox__slide {
    width: 600px;
    height: 300px;
  }
}
.loadingBox__slide__img {
  width: 100px;
  margin: auto;
}
@media (min-width: 768px) {
  .loadingBox__slide__img {
    width: 150px;
  }
}
.loadingBox__slide__img img {
  width: 100%;
}
.loadingBox__slide__item {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 7;
  width: 100%;
  height: 100%;
  transform: translateX(100%);
  padding: 0 15px;
  animation: opaqTransition 48s ease-in-out infinite;
}
@media (min-width: 768px) {
  .loadingBox__slide__item {
    width: 600px;
    transform: translateX(600px);
    padding: 0 30px;
  }
}
.loadingBox__slide__item.item-2 {
  z-index: 6;
  animation-delay: 12s;
}
.loadingBox__slide__item.item-3 {
  z-index: 5;
  animation-delay: 24s;
}
.loadingBox__slide__item.item-4 {
  z-index: 4;
  animation-delay: 36s;
}
.loadingBox__slide__title {
  font-size: 20px;
  color: #262626;
  font-weight: 600;
  margin-bottom: 30px;
  text-align: center;
  text-transform: uppercase;
}
.loadingBox__slide__text {
  margin-top: 30px;
  text-align: center;
}
.loadingBox__slide__text p {
  font-size: 14px;
}
.loadingBox__slide__text p.p2 {
  margin-bottom: 0;
}
progress {
  display: block;
  width: 100%;
  height: 7px;
  border: 0;
  background: #d8d8d8;
  border-radius: 30px;
}
.hidden {
  display: none !important;
}
.d-flex,
.hotel-animated,
.hotel-animated__price {
  display: flex;
}
h1 {
  font-size: 26px;
  color: #262626;
}
.w100 {
  width: 100% !important;
}
.text-center {
  text-align: center;
}
.mb15 {
  margin-bottom: 15px;
}
.mb30 {
  margin-bottom: 30px;
}
.mb20 {
  margin-bottom: 20px;
}
.mr10 {
  margin-right: 10px;
}
.mr15 {
  margin-right: 15px;
}
.mr5 {
  margin-right: 5px;
}
.ml10 {
  margin-left: 10px;
}
.ml15 {
  margin-left: 15px;
}
.ml5 {
  margin-left: 5px;
}
.m0 {
  margin: 0;
}
.mt10 {
  margin-top: 10px;
}
.size-14 {
  font-size: 14px;
}
.size-16 {
  font-size: 16px;
}
.size-12 {
  font-size: 12px;
}
.form-group > label,
.title1 {
  font-size: 14px;
  color: #000;
}
.mt5 {
  margin-top: 5px;
}
.form-group {
  margin-bottom: 15px;
}
.form-group > label {
  margin-bottom: 5px;
}
@keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}
.animated-background,
.hotel-animated__img {
  animation-duration: 1.25s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background: #f6f6f6;
  background: linear-gradient(to right, #f6f6f6 8%, #f0f0f0 18%, #f6f6f6 33%);
  background-size: 800px 104px;
  height: 96px;
  position: relative;
}
.hotel-animated {
  background-color: #fff;
  width: 100%;
  height: 130px;
  margin-bottom: 15px;
  justify-content: space-between;
  border: 1px solid #eee;
  border-radius: 4px;
}
.hotel-animated__img {
  width: 70px;
  height: 100%;
}
.hotel-animated__content {
  width: calc(100% - 70px);
  padding: 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.hotel-animated__line1 {
  width: 100%;
  height: 15px;
  margin-bottom: 10px;
}
.hotel-animated__line4 {
  width: 100px;
  height: 8px;
  margin: 15px 0 5px;
}
.hotel-animated__line5 {
  width: 80px;
  height: 8px;
}
.hotel-animated__line6,
.hotel-animated__line7 {
  height: 20px;
  width: 80px;
  border-radius: 3px;
}
.hotel-animated__line6 {
  margin-right: 15px;
}
.title1 {
  text-transform: uppercase;
  margin-bottom: 15px;
}
.title1 h4 {
  display: inline-block;
  border-bottom: 1px solid #fa8c16;
  padding-bottom: 5px;
}
.btn,
.form-control {
  align-items: center;
  padding: 0 15px;
}
.wrapper {
  min-height: calc(100vh - 140px);
}
.btn {
  font-size: 16px;
  text-align: center;
  white-space: nowrap;
  touch-action: manipulation;
  cursor: pointer;
  user-select: none;
  border-radius: 4px;
  transition: all 0.3s;
  height: 48px;
  border: 1px solid transparent;
  display: inline-flex;
  justify-content: center;
}
.btn.btn-max-with {
  width: 100%;
}
.btn:hover {
  opacity: 0.8;
}
.btn.btn_orange {
  background: #fa8c16;
  color: #fff;
}
.btn.btn_orange_line {
  border: 1px solid #fa8c16;
  color: #fa8c16;
}
.btn.font-weight-5 {
  font-weight: 500;
}
.btn.btn_blue {
  background: #1890ff;
  color: #fff;
}
.btn.btn-update {
  background: #389e0d;
  color: #fff;
}
.btn.btn-san-ve {
  background: #fa8c16;
  color: #fff;
  width: 100%;
}
.btn.btn_outline {
  border-color: #fa8c16;
  background: #fff;
  color: #fa8c16;
}
.btn.btn_disabled {
  cursor: default;
  opacity: 0.5;
}
.btnGroup {
  width: 175px;
  margin: 15px auto;
}
@media (max-width: 767px) {
  .btnGroup {
    width: 100%;
  }
}
.btnGroup .btn {
  width: 100%;
}
.btnGroup .btn a {
  color: #fff;
}
.form-control,
.radio label {
  font-size: 14px;
  color: #262626;
}
.btnGroupMobile {
  display: none;
  margin-bottom: 0;
}
@media (max-width: 991px) {
  .btnGroupMobile {
    display: block;
  }
}
.form-control {
  display: inline-flex;
  border: 1px solid rgba(0, 0, 0, 0.15);
  background: #fff;
  border-radius: 3px;
  width: 100%;
  height: 40px;
  -webkit-appearance: none;
  -moz-appearance: none;
}
.form-control[disabled] {
  background-color: #f5f5f5 !important;
  cursor: not-allowed;
}
select.form-control {
  background-image: url(https://statics.vntrip.vn/images/arrow-down.svg);
  background-repeat: no-repeat;
  background-position: right 10px center;
}
.radio {
  display: flex;
}
.radio input[type="radio"] {
  display: none;
}
.radio label {
  position: relative;
  cursor: pointer;
  margin-bottom: 0;
  padding-left: 25px;
}
.radio input[type="radio"] + label:before {
  content: "";
  width: 16px;
  height: 16px;
  position: absolute;
  left: 0;
  top: 3px;
  display: inline-block;
  text-align: center;
  border: 1px solid #d9d9d9;
  border-radius: 50%;
  background-color: #fff;
}
.radio input[type="radio"]:checked + label:before {
  border: 3px solid #fff;
  box-shadow: 0 0 0 1px #1890ff;
  background: #1890ff;
}
.radio.radio_lg label {
  padding-left: 40px;
  line-height: 1.5;
}
.radio.radio_lg input[type="radio"] + label:before {
  width: 24px;
  height: 24px;
  top: 0;
}
.radio.radio_lg input[type="radio"]:checked + label:before {
  border-width: 4px;
}
.radio.disabled {
  cursor: not-allowed;
  opacity: 0.5;
}
.imgBox {
  text-align: center;
}
.imgBox img {
  width: 100%;
}
.hotel-error h2 {
  font-weight: 600;
  font-size: 20px;
  margin-bottom: 15px;
}
.hotel-error p {
  color: #595959;
  margin-bottom: 30px;
}
.hotel-error .btn {
  /*width: 416px;*/
  font-size: 14px;
}
@media (max-width: 767px) {
  .hotel-error p br {
    display: none;
  }
  .hotel-error .btn {
    width: 100%;
  }
}
.hotel-error .p-line {
  font-weight: 400;
  display: block;
  color: #8c8c8c;
  margin: 13px 0 15px;
}
.vntLoading .v-loading-page__text p,
.vntLoading__text {
  color: #262626;
  margin-top: 20px;
  font-size: 14px;
}
.hotel-error .tip-hotel {
  color: #262626;
  font-size: 12px;
  margin-bottom: 0;
  margin-top: 5px;
}
.hotel-error .tip-hotel span {
  font-size: 16px;
  margin-left: 5px;
  font-weight: 600;
}
.vntLoading {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
  background: rgba(0, 0, 0, 0.7);
}
.vntLoading__cont {
  width: 350px;
  padding: 15px;
  background: #fff;
  text-align: center;
  border-radius: 4px;
}
.vntLoading__cont.default {
  padding: 20px 15px;
}
@media (max-width: 767px) {
  .vntLoading__cont {
    width: 70%;
  }
}
.vntLoading__text {
  display: inline-block;
}
.vntLoading__icon {
  display: flex;
  justify-content: center;
  align-items: center;
}
.vntLoading .v-loading-page__dot {
  width: 7px;
  height: 7px;
  margin-left: 10px;
  margin-right: 10px;
  background-color: #fa8c16;
}
.vntLoading .v-loading-page__text__animation {
  width: 100%;
  height: 75px;
  position: relative;
}
.vntLoading .v-loading-page__text__animation p {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 7;
  width: 100%;
  height: 100%;
  opacity: 0;
  padding: 0;
  animation: loading-text 6s forwards;
  margin-top: 10px;
  display: flex;
  align-items: center;
}
@media (min-width: 768px) {
  .vntLoading .v-loading-page__text__animation p {
    width: 100%;
    padding: 0;
  }
}
.vntLoading .v-loading-page__text__animation p.item2 {
  z-index: 6;
  animation-delay: 6s;
}
.vntLoading .v-loading-page__text__animation p.item3 {
  z-index: 5;
  animation-delay: 12s;
}
.vntLoading .v-loading-page__text__animation p.item4 {
  z-index: 4;
  animation-delay: 18s;
}
.vntLoading .v-loading-page__text__animation p.item5 {
  z-index: 4;
  animation-delay: 24s;
}
.vntLoading .v-loading-page__text__animation p.item6 {
  z-index: 4;
  animation-delay: 30s;
}
.vntLoading .v-loading-page__text__animation p.item7 {
  z-index: 4;
  animation-delay: 36s;
}
.vntLoading .v-loading-page__text__animation p.item8 {
  z-index: 4;
  animation: loading-text-2 6s forwards;
  animation-delay: 42s;
}
.vntLoading .v-loading-page__dots {
  height: 20px;
  margin-top: 10px;
}
.v-loading-page__dots {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 12px;
  width: 100%;
}
.v-loading-page__dot {
  margin-left: 7px;
  margin-right: 7px;
  display: inline-block;
  width: 5px;
  height: 5px;
  background-color: #fa8c16;
  border-radius: 50%;
  animation-name: loading-dot;
  animation-duration: 1.1s;
  animation-iteration-count: infinite;
}
.v-loading-page__dot--second {
  animation-delay: 0.25s;
}
.v-loading-page__dot--third {
  animation-delay: 0.5s;
}
@keyframes loading-dot {
  35% {
    transform: scale(2);
  }
}
@keyframes loading-text-2 {
  0% {
    opacity: 0;
  }
  100%,
  15%,
  25%,
  50%,
  75% {
    opacity: 1;
  }
}
@keyframes loading-text {
  0%,
  100% {
    opacity: 0;
  }
  15%,
  25%,
  50% {
    opacity: 1;
  }
  75% {
    opacity: 0.8;
  }
}
.header {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}
.header__logo img {
  width: 100px;
}
.header__hotline {
  font-size: 14px;
  color: #389e0d;
  border: 1px solid #389e0d;
  border-radius: 30px;
  padding: 5px 10px;
  display: flex;
  align-items: center;
}
.header__hotline:hover {
  color: #389e0d;
}
.header__hotline svg {
  margin-right: 10px;
}
.header .container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 70px;
}
.banner {
  margin-bottom: 15px;
  position: relative;
}
.banner img {
  width: 100%;
  object-fit: cover;
}
@media (max-width: 991px) {
  .banner img.banner-mobile {
    display: block;
    height: auto;
  }
  .banner img.banner-desktop {
    display: none;
  }
}
@media (min-width: 992px) {
  .banner img.banner-mobile {
    display: none;
  }
  .banner img.banner-desktop {
    height: 626px;
  }
}
.form-box-search {
  position: absolute;
  top: 25px;
  min-height: 570px;
  background: #fff;
  border-radius: 8px;
  width: 442px;
  box-shadow: 1px 0 8px rgba(0, 0, 0, 0.15);
}
.form-box-search .title-search {
  background: #fff7e6;
  font-weight: 600;
  font-size: 20px;
  padding: 8px;
  border-radius: 8px 8px 0 0;
  text-align: center;
}
@media (max-width: 991px) {
  .form-box-search {
    min-height: 500px;
    position: relative;
    top: -15px;
    width: auto;
  }
  .form-box-search .title-search {
    display: none;
  }
}
.form-box-search .box-search-content {
  padding: 12px;
}
.form-box-search .box-search-item-2 {
  display: flex;
}
.form-box-search .box-search-item-2 .form-group {
  width: 50%;
}
.form-box-search .box-search-item-2 .form-group:first-child {
  padding-right: 5px;
}
.form-box-search .box-search-item-2 .form-group:last-child {
  padding-left: 5px;
}
.form-box-search .form-group {
  margin-bottom: 10px;
  position: relative;
}
.form-box-search .form-group .formBox__input .form-control {
  width: 100%;
  font-size: 13px;
}
@media (max-width: 991px) {
  .form-box-search .box-info-passenger {
    display: block;
  }
  .form-box-search .box-info-passenger .form-group {
    width: 100%;
    padding: 0;
  }
  .form-box-search .box-info-passenger .form-group:first-child,
  .form-box-search .box-info-passenger .form-group:last-child {
    padding: 0;
  }
  .form-box-search .box-info-passenger .form-control {
    width: 100%;
  }
  .form-box-search .form-group {
    margin-bottom: 15px;
  }
  .form-box-search .form-group > .btn-passenger {
    padding: 10px 30px;
  }
  .form-box-search .form-group .formBox__input .form-control {
    padding: 10px 30px;
    font-size: 14px;
  }
}
.form-box-search .form-group .form-icon {
  display: none;
}
.form-box-search .form-control {
  padding: 0 12px;
}
@media (max-width: 991px) {
  .form-box-search .form-group .form-icon {
    display: block;
    position: absolute;
    top: 50%;
    left: 10px;
    transform: translateY(-50%);
    z-index: 1;
  }
  .form-box-search .form-control {
    height: 40px;
  }
  .form-box-search .box-search-item .form-group label {
    display: none;
  }
}
.form-box-search .box-search-item .passenger {
  position: relative;
}
.form-box-search .box-search-item .passenger .btn-passenger {
  width: 100%;
  border-radius: 4px;
  height: 40px;
  background: #fff;
  border: 1px solid rgba(0, 0, 0, 0.15);
  padding: 0 9px;
  text-align: left;
  font-size: 13px;
  line-height: 32px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  transition: 0s;
}
@media (max-width: 991px) {
  .form-box-search .box-search-item .passenger .btn-passenger {
    height: 40px;
    font-size: 14px;
    padding: 0 30px;
  }
}
.form-box-search .box-search-item .passenger .passenger__body {
  width: 100%;
  background-color: #fff;
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 160px;
  padding: 5px 0;
  margin: 2px 0 0;
  font-size: 14px;
  text-align: left;
  list-style: none;
  background-clip: padding-box;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
}
@media (max-width: 991px) {
  .form-box-search .box-search-item .passenger .passenger__body {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border: none;
    box-shadow: none;
    border-radius: 0;
    margin: 0;
    padding: 0;
    overflow: hidden;
    transition: 0.4s ease-in-out;
    transform: translateY(100%);
    visibility: hidden;
    display: block;
  }
}
.form-box-search
  .box-search-item
  .passenger
  .passenger__body
  .passenger_header {
  display: none;
}
@media (max-width: 991px) {
  .form-box-search
    .box-search-item
    .passenger
    .passenger__body
    .passenger_header {
    background-color: #ff8916;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 15px;
    color: #fff;
    font-weight: 600;
  }
  .form-box-search
    .box-search-item
    .passenger
    .passenger__body
    .passenger_header
    .passenger__close
    button {
    color: #fff;
    font-size: 25px;
    font-weight: 600;
  }
  .form-box-search .box-search-item .passenger.open .passenger__body {
    visibility: visible;
    transform: translateY(0) !important;
  }
}
.form-box-search .box-search-item .passenger .passenger__body li {
  padding: 10px 20px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.form-box-search .box-search-item .passenger .passenger__body li:last-child {
  border: none;
}
.form-box-search .box-search-item .passenger.open .passenger__body {
  display: block;
}
.form-box-search .box-search-item .caret {
  position: absolute;
  top: 50%;
  right: 5px;
  transform: translateY(-50%);
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 2px;
  border-top: 4px dashed rgba(0, 0, 0, 0.15);
  border-top: 4px solid rgba(0, 0, 0, 0.15);
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
}
.form-box-search .box-search-item .combo-item-content {
  background: #fff7e6;
  border: 1px solid #fa8c16;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.form-box-search .box-search-item .combo-item-content span {
  font-size: 16px;
  color: #262626;
}
@media (max-width: 991px) {
  .form-box-search .box-search-item .combo-item-content {
    padding: 9px 8px;
  }
  .form-box-search .box-search-item .combo-item-content span {
    font-size: 14px;
  }
}
.form-box-search .box-search-item .combo-item-content .combo-price {
  color: #cf1322;
  font-size: 24px;
  margin-bottom: 0;
  font-weight: 600;
}
@media (max-width: 991px) {
  .form-box-search .box-search-item .combo-item-content .combo-price {
    font-size: 20px;
  }
  .form-box-search .box-search-item .combo-item-content .combo-price span {
    font-size: 14px;
  }
}
.form-box-search .box-search-item .combo-item-content .combo-price span {
  color: #cf1322;
  font-size: 16px;
}
.form-box-search .box-search-item .list-combo {
  padding: 18px 0 0;
}
.form-box-search .box-search-item .list-combo .combo-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;
}
.form-box-search .box-search-item .list-combo .combo-item span {
  font-size: 14px;
}
@media (max-width: 991px) {
  .form-box-search .box-search-item .list-combo .combo-item span {
    font-size: 12px;
  }
}
.form-box-search .box-search-item .list-combo .combo-item span.combo-price {
  font-size: 14px;
}
@media (max-width: 320px) {
  .form-box-search .box-search-item .list-combo .combo-item span.combo-price {
    font-size: 13px;
  }
}
.form-box-search input::-webkit-input-placeholder {
  color: #bcbcbc;
  font-size: 12px;
}
.form-box-search input::-moz-placeholder {
  color: #bcbcbc;
  font-size: 12px;
}
.form-box-search input:-ms-input-placeholder {
  color: #bcbcbc;
  font-size: 12px;
}
.form-box-search input:-moz-placeholder {
  color: #bcbcbc;
  font-size: 12px;
}
.listPrice {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-left: -7.5px;
  margin-right: -7.5px;
}
.listPrice li {
  width: 50%;
  padding: 0 7.5px;
  margin-bottom: 15px;
}
.listPrice__item {
  border: 2px solid #f2994a;
  border-radius: 3px;
  text-align: center;
  padding: 5px 10px;
}
.listPrice__item p {
  font-size: 16px;
  font-weight: 700;
}
.listPrice__item p.p1 {
  color: #dc2028;
}
.listPrice__item p.p2 {
  color: #262626;
  margin-bottom: 0;
}
@media (min-width: 1200px) {
  .listIcon {
    width: 50%;
    margin-left: auto;
    margin-right: auto;
  }
}
.listIcon li {
  display: flex;
  align-items: center;
  padding-bottom: 5px;
}
.listIcon li:last-child {
  padding-bottom: 0;
}
.listIcon li span {
  font-size: 14px;
  flex: 1;
  padding-left: 15px;
}
.comments {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  background-color: rgba(16, 20, 38, 0.8);
  background-blend-mode: multiply;
  padding-bottom: 15px;
  margin-bottom: 0;
}
@media (min-width: 768px) {
  .comments {
    padding-bottom: 2vw;
    margin-bottom: 0;
  }
}
.comments__ava {
  width: 80px;
  height: 80px;
  border: 2px solid #fff;
  border-radius: 50%;
  margin: auto;
  overflow: hidden;
}
.footer,
.listServices {
  border-top: 1px solid #eee;
}
@media (min-width: 768px) {
  .comments__ava {
    width: 5vw;
    height: 5vw;
  }
}
.comments__ava img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.comments__slide {
  padding: 40px 0;
}
.comments__slide .slick-dots {
  bottom: 0;
}
.comments__slide .slick-dots li.slick-active button {
  background: #fa8c16;
}
.comments__slide .slick-dots li button {
  background: #d9d9d9;
}
.comments__item {
  text-align: center;
  color: #fff;
}
.comments__item h2 {
  margin-bottom: 30px;
  color: #fff;
}
@media (min-width: 768px) {
  .comments__slide {
    padding: 5vw 0;
  }
  .comments__slide .slick-dots li button {
    border-radius: 8px;
  }
  .comments__item h2 {
    margin-bottom: 1.5vw;
  }
}
.comments__item p.p1 {
  font-size: 18px;
  display: flex;
  margin-bottom: 30px;
}
@media (min-width: 768px) {
  .comments__item p.p1 {
    font-size: 1.2vw;
    margin-bottom: 2vw;
  }
  .comments__item p.p1 svg {
    width: 5vw;
    margin-right: 1vw;
  }
}
@media (max-width: 767px) {
  .comments__item p.p1 {
    font-size: 15px;
  }
  .comments__item p.p1 svg {
    display: none;
  }
}
.comments__item p.p2 {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 15px;
}
@media (min-width: 768px) {
  .comments__item p.p2 {
    font-size: 1.1vw;
    margin-bottom: 1vw;
  }
}
.list-tip-combo {
  padding: 40px 0;
  display: flex;
}
.list-tip-combo .tip-combo-item {
  width: calc(100% / 3);
  font-size: 14px;
  text-align: center;
}
.list-tip-combo .tip-combo-item h2 {
  font-size: 16px;
}
.list-tip-combo .tip-combo-item .list-text h3 {
  color: #595959;
  font-size: 14px;
  font-weight: 400;
}
.list-tip-combo .tip-combo-item p {
  font-weight: 600;
}
.list-tip-combo .tip-combo-item ul li {
  color: #595959;
}
@media (max-width: 767px) {
  .list-tip-combo {
    display: block;
  }
  .list-tip-combo .tip-combo-item {
    width: 100%;
    margin-bottom: 20px;
    text-align: center;
  }
}
.home__item {
  padding-bottom: 30px;
}
.home__item .title {
  text-align: center;
  padding: 30px 0;
}
.home__item .title h2 {
  font-size: 24px;
  font-weight: 600;
}
.home__item .title span {
  font-size: 14px;
  color: #262626;
}
.tutorial__combo {
  background: #f5f5f5;
}
.tutorial__combo .list__step {
  display: flex;
  justify-content: space-between;
}
.tutorial__combo .list__step .item__tutorial {
  width: calc(100% / 3 - 30px);
  text-align: center;
}
.tutorial__combo .list__step .item__tutorial img {
  width: 100%;
}
.tutorial__combo .list__step .item__tutorial .image {
  margin-bottom: 24px;
}
.tutorial__combo .list__step .item__tutorial .text p.step {
  font-size: 20px;
  font-weight: 600;
  color: #262626;
}
.tutorial__combo .list__step .item__tutorial .text p.title__text {
  color: #262626;
  font-size: 16px;
  font-weight: 600;
}
.tutorial__combo .list__step .item__tutorial .text p {
  font-size: 14px;
  color: #595959;
}
@media (max-width: 767px) {
  .tutorial__combo .list__step {
    display: block;
  }
  .tutorial__combo .list__step .item__tutorial {
    width: 100%;
    margin-bottom: 30px;
  }
  .tutorial__combo .list__step .item__tutorial:last-child {
    margin-bottom: 0;
  }
}
.footer__top,
.footer__top__item p {
  display: flex;
}
.question__combo__content {
  width: 70%;
  margin: auto;
}
@media (max-width: 991px) {
  .question__combo__content {
    width: 100%;
  }
}
.listServices {
  padding: 15px 0;
  border-bottom: 1px solid #eee;
}
.footer__middle,
.footer__top {
  border-bottom: 1px solid #d9d9d9;
}
.listServices__item {
  display: flex;
  padding-bottom: 15px;
}
.listServices__item:last-child {
  padding-bottom: 0;
}
.listServices__text {
  text-align: right;
  width: calc(100% - 40px);
  padding-right: 15px;
}
.listServices__text p {
  color: #262626;
}
.listServices__text p.p1 {
  font-size: 18px;
  font-weight: 700;
}
.listServices__text p.p2 {
  font-size: 14px;
  margin-bottom: 0;
}
.listServices__icon {
  width: 40px;
}
.listServices__icon img {
  width: 100%;
}
.textCombo__item {
  margin-bottom: 15px;
}
.textCombo p,
.textCombo ul li {
  font-size: 14px;
  color: #000;
  font-weight: 600;
}
.footer {
  padding: 5px 15px;
  height: 70px;
  bottom: 0;
  position: inherit;
}
@media (max-width: 767px) {
  .footer {
    padding: 0;
  }
}
@media (max-width: 991px) {
  .footer__content {
    padding-bottom: 110px;
  }
}
.footer__top {
  padding: 30px 0;
  justify-content: space-between;
}
.footer__top__item {
  width: 20%;
}
.footer__top__item .info p,
.footer__top__item .logo__image {
  margin-bottom: 10px;
}
.footer__top__item:first-child {
  width: 33%;
}
.footer__top__item .title {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 10px;
}
.footer__top__item ul {
  list-style: inherit;
  padding-left: 20px;
}
.footer__top__item ul li {
  margin-bottom: 10px;
  color: #595959;
}
.footer__top__item .company__name {
  font-size: 14px;
  color: #000;
  font-weight: 600;
  margin-bottom: 15px !important;
}
.footer__bottom p,
.footer__middle__text p,
.footer__top__item p a {
  color: #595959;
}
.footer__top__item p svg {
  margin-right: 10px;
  width: 20px;
  transform: translateY(1px);
}
.footer__top__item p span {
  color: #595959;
  width: calc(100% - 30px);
}
@media (max-width: 767px) {
  .footer__top {
    display: inherit;
  }
  .footer__top__item,
  .footer__top__item:first-child {
    width: 100%;
  }
}
.footer__middle {
  padding: 25px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.footer__middle__text {
  width: 60%;
  margin-right: 30px;
}
.footer__middle__image {
  margin-right: 0px;
}
@media (max-width: 767px) {
  .footer__middle {
    display: block;
    text-align: center;
  }
  .footer__middle__text {
    width: 100%;
    margin-bottom: 10px;
  }
  .footer__middle__image {
    justify-content: center;
    margin-right: 0;
  }
}
.footer__bottom {
  text-align: center;
  padding: 15px 0;
}
.footer__giayphep {
  width: 130px;
  margin-left: 15px;
}
.footer__giayphep img {
  width: 100%;
}
.footer__bocongthuong a,
.footer__giayphep img a {
  display: block;
}
.footer__bocongthuong a img {
  width: 150px;
  display: block;
}
@media (max-width: 991px) {
  .footer__bocongthuong a img {
    margin: auto;
  }
}
.inputIcon {
  position: relative;
  display: inline-flex;
  align-items: center;
  padding: 0 15px;
  font-size: 14px;
  color: #262626;
  border: 1px solid rgba(0, 0, 0, 0.15);
  background: #fff;
  border-radius: 3px;
  width: 100%;
  height: 40px;
  text-align: left;
}
.inputIcon > svg {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 15px;
}
.formBox__item,
.step4 .page-content,
.step5 .page-content,
.step6 .page-content {
  display: flex;
  position: relative;
}
.inputIcon span {
  padding-left: 30px;
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.formBox__item {
  align-items: baseline;
  margin-bottom: 15px;
}
.formBox__title {
  width: 30%;
}
.formBox__title span {
  font-size: 14px;
  color: #000;
}
.formBox__col {
  width: 70%;
  padding-left: 15px;
}
.step3 {
}
.step3 .info-flight {
  font-weight: 600;
}
.step6 {
  padding-top: 20px;
}
.step6 .radio input[type="radio"]:checked + label:before {
  border: 3px solid #fff;
  box-shadow: 0 0 0 1px #fa8c16;
  background: #fa8c16;
}
.step4,
.step5,
.step6 {
  padding: 20px 0;
}
.step4 .page-right,
.step5 .page-right,
.step6 .page-right {
  width: calc(100% - 360px);
  margin-right: 30px;
}
@media (max-width: 767px) {
  .step4 .page-right .tip-content,
  .step5 .page-right .tip-content,
  .step6 .page-right .tip-content {
    font-size: 12px;
  }
}
@media (max-width: 991px) {
  .step4 .page-right,
  .step5 .page-right,
  .step6 .page-right {
    width: 100%;
    margin-right: 0;
    order: 1;
  }
  .step4 .page-right h2.mb15,
  .step5 .page-right h2.mb15,
  .step6 .page-right h2.mb15 {
    margin-bottom: 8px;
  }
  .step4 .page-content,
  .step5 .page-content,
  .step6 .page-content {
    flex-wrap: wrap;
  }
}
.step4 .page-content .hotelReview__info__content,
.step5 .page-content .hotelReview__info__content,
.step6 .page-content .hotelReview__info__content {
  width: 360px;
}
@media (max-width: 991px) {
  .step4 .page-content .hotelReview__info__content,
  .step5 .page-content .hotelReview__info__content,
  .step6 .page-content .hotelReview__info__content {
    width: 100%;
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    border: none;
    box-shadow: none;
    border-radius: 0;
    margin: 0;
    padding: 0;
    overflow: hidden;
    transition: 0.4s ease-in-out;
    transform: translateY(100%);
    background: #fff;
    z-index: 100;
    visibility: hidden;
    display: block;
  }
  .step4 .page-content .hotelReview__info__content .btnGroup,
  .step5 .page-content .hotelReview__info__content .btnGroup,
  .step6 .page-content .hotelReview__info__content .btnGroup {
    display: none;
  }
  .step4 .page-content .hotelReview__info__content .btnGroupMobile,
  .step5 .page-content .hotelReview__info__content .btnGroupMobile,
  .step6 .page-content .hotelReview__info__content .btnGroupMobile {
    display: none;
    margin-bottom: 0;
  }
}
@media (max-width: 991px) and (max-width: 991px) {
  .step4 .page-content .hotelReview__info__content .btnGroupMobile,
  .step5 .page-content .hotelReview__info__content .btnGroupMobile,
  .step6 .page-content .hotelReview__info__content .btnGroupMobile {
    display: block;
  }
}
.step4 .page-content .menu-mobile__backdrop,
.step5 .page-content .menu-mobile__backdrop,
.step5 .pageCheckOutContent .menu-mobile__backdrop,
.step6 .page-content .menu-mobile__backdrop {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border: none;
  box-shadow: none;
  border-radius: 0;
  margin: 0;
  padding: 0;
  overflow: hidden;
  transition: 0.4s ease-in-out;
  opacity: 0;
  background: rgba(0, 0, 0, 0.25);
  z-index: 99;
  visibility: hidden;
  display: none;
}
.suggestion__backdrop,
.suggestion__main {
  transition: all 0.3s ease-in-out;
  visibility: hidden;
}
@media (max-width: 991px) {
  .step4 .page-content .menu-mobile__backdrop,
  .step5 .page-content .menu-mobile__backdrop,
  .step5 .pageCheckOutContent .menu-mobile__backdrop,
  .step6 .page-content .menu-mobile__backdrop {
    display: block;
  }
}
.step4 .page-content.open .hotelReview__info__content,
.step5 .page-content.open .hotelReview__info__content,
.step5 .pageCheckOutContent.open .hotelReview__info__content,
.step6 .page-content.open .hotelReview__info__content {
  visibility: visible;
  transform: translateY(0);
}
.step4 .page-content.open .menu-mobile__backdrop,
.step5 .page-content.open .menu-mobile__backdrop,
.step5 .pageCheckOutContent.open .menu-mobile__backdrop,
.step6 .page-content.open .menu-mobile__backdrop {
  opacity: 1;
  visibility: visible;
}
.step4 .info-flight,
.step5 .info-flight,
.step6 .info-flight {
  font-weight: 600;
}
.step4 .btn-mobile,
.step5 .btn-mobile,
.step6 .btn-mobile {
  display: none;
  box-shadow: 0 -2px 6px rgba(0, 0, 0, 0.15);
}
@media (max-width: 991px) {
  .step4 .btn-mobile,
  .step5 .btn-mobile,
  .step6 .btn-mobile {
    display: block;
    position: fixed;
    bottom: 0;
    left: 0;
    background: #fff;
    margin: 0;
    z-index: 2;
    padding: 15px;
  }
  .step4 .btn-mobile .btn,
  .step5 .btn-mobile .btn,
  .step6 .btn-mobile .btn {
    margin-top: 12px;
  }
}
.hotelReview__info {
  padding: 25px;
  border: 1px solid #eee;
}
.hotelReview__info .title-hotel {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 15px;
  font-weight: 600;
}
.hotelReview__info .title-hotel .hotelReview__info__close {
  display: none;
}
@media (max-width: 991px) {
  .hotelReview__info {
    padding: 15px;
  }
  .hotelReview__info .title-hotel .hotelReview__info__close {
    display: block;
  }
}
.hotelReview__info .title-hotel .hotelReview__info__close button {
  font-size: 20px;
  font-weight: 500;
}
.hotelReview__info h2.title {
  margin-bottom: 0;
  font-weight: 600;
  text-transform: uppercase;
  font-size: 14px;
}
.hotelReview__info .image-hotel {
  width: 100%;
  object-fit: cover;
  height: 117px;
  margin-bottom: 10px;
}
.hotelReview__info h3 {
  font-weight: 600;
  font-size: 18px;
  color: #222;
  margin-bottom: 10px;
}
.hotelReview__info h3 .form-room__showmore {
  color: #336ce0;
  font-size: 14px;
  margin-left: 5px;
  font-weight: 400;
  display: none;
}
@media (max-width: 991px) {
  .hotelReview__info h3 .form-room__showmore {
    display: inline-block;
  }
}
.hotelReview__info__group .hotelReview__info__item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 5px;
}
.hotelReview__info__other .hotelReview__other__item,
.hotelReview__info__price .hotelReview__price__item {
  justify-content: space-between;
  display: flex;
}
.hotelReview__info__group .hotelReview__info__item p {
  font-size: 14px;
  color: #000;
  margin-bottom: 0;
}
.hotelReview__info__group .hotelReview__info__item p:first-child {
  color: #595959;
}
.hotelReview__info__group .hotelReview__info__item p:first-child svg {
  margin-right: 10px;
  vertical-align: middle;
  fill: #595959;
}
.hotelReview__info__border {
  width: 100%;
  border-bottom: 1px solid #d8d8d8;
  margin: 10px 0;
}
.hotelReview__info__price .hotelReview__price__item p {
  color: #222;
  font-size: 14px;
}
.hotelReview__info__price .hotelReview__price__item p.price {
  color: #000;
  font-weight: 600;
}
.hotelReview__info__other .hotelReview__other__item p {
  color: #222;
  font-size: 14px;
}
.hotelReview__info__other .hotelReview__other__item p.price {
  color: #ff8917;
  font-weight: 600;
}
.hotelReview__info__totalPrice .d-flex {
  justify-content: space-between;
  display: flex;
}
.hotelReview__info__totalPrice .d-flex .detail{
  width: 100px;
}
.hotelReview__info__totalPrice .d-flex p {
  color: #000;
  margin-bottom: 0;
  font-weight: 600;
  font-size: 18px;
}
.hotelReview__info__totalPrice .d-flex p.price {
  color: #fa8c16;
}
.hotelReview__info__fee {
  color: #666;
  font-size: 13px;
}
@media (max-width: 991px) {
  .hotelReview__info__fee {
    font-size: 12px;
  }
}
.hotelReview__info__fee span.detail {
  color: #1890ff;
  display: flex;
  justify-content: flex-end;
}
.hotelReview__info__fee span.detail svg {
  margin-left: 5px;
  transform: translateY(3px);
}
.step4 .btn_black_mobile {
  position: relative;
  top: 40px;
  font-weight: 600;
  font-size: 14px;
  padding: 10px;
  display: flex;
  align-items: center;
}
.step4 .btn_black_mobile span {
  margin-left: 10px;
  cursor: pointer;
}
@media (max-width: 767px) {
  .step4 .btn_black_mobile {
    position: fixed;
    top: 0;
    left: 0;
    background: #fff;
    width: 100%;
    height: 70px;
    z-index: 2;
    justify-content: left;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  }
}
.step4 .hotelFourStart {
  padding: 10px 15px;
  border-radius: 8px;
  border: 1px solid #389e0d;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #f6ffed;
  margin-bottom: 30px;
}
.step4 .hotelFourStart .btn {
  font-size: 14px;
  padding: 10px 30px;
}
@media (max-width: 320px) {
  .step4 .hotelFourStart {
    display: inherit;
    text-align: center;
  }
  .step4 .hotelFourStart .btn {
    width: 50%;
    height: 40px;
    padding: 10px 16px;
  }
}
.step4 .hotelFourStart p {
  font-size: 14px;
  color: #262626;
}
.step4 .hotelFourStart p.plus-price {
  font-size: 14px;
}
.step4 .hotelFourStart p.plus-price span {
  font-size: 16px;
  margin-left: 5px;
  font-weight: 600;
}
@media (max-width: 767px) {
  .step4 .hotelFourStart .btn {
    height: 40px;
    padding: 10px 16px;
  }
  .step4 .hotelFourStart p.plus-price span {
    font-size: 14px;
  }
}
.step2 .listPrice li.active .listPrice__item {
  border-color: #fa8c16;
  background-color: #fff2e7;
}
.step2 .listPrice li.active .listPrice__item p.p1 {
  color: #dc2028;
}
.step2 .listPrice__item {
  border-color: #bdbdbd;
  cursor: pointer;
}
.step2 .listPrice__item p.p1 {
  color: #bdbdbd;
}
.suggestion {
  position: absolute;
  box-shadow: 1px 0 8px 4px rgba(0, 0, 0, 0.15);
  width: 418px;
  top: 60px;
  z-index: 2;
  display: none;
  border-radius: 5px;
}
.passenger1,
.passenger1__backdrop,
.suggestion__backdrop {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.suggestion.open,
.suggestion.open .suggestion {
  display: block;
}
@media (max-width: 991px) {
  .suggestion {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 99;
    overflow: hidden;
    transition: all 0.3s ease-in-out;
    visibility: hidden;
    width: 100%;
    display: block;
    border-radius: 0;
  }
  .suggestion.open .suggestion__main {
    transform: none;
  }
}
.suggestion.open,
.suggestion.open .suggestion__main {
  visibility: visible;
}
@media (min-width: 992px) {
  .suggestion.open .suggestion__main {
    transform: translateX(0);
  }
}
.suggestion.open .suggestion__backdrop {
  visibility: visible;
  opacity: 1;
}
.suggestion__main {
  position: relative;
  z-index: 10;
  background: #fff;
  height: 100%;
  border-radius: 5px;
}
.suggestion__backdrop {
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  opacity: 0;
}
.suggestion__header {
  display: none;
}
@media (max-width: 991px) {
  .suggestion__main {
    transform: translateY(100%);
    border-radius: 0;
  }
  .suggestion__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 60px;
    padding: 0 15px;
    background-color: #fa8c16;
  }
  .suggestion__header .form-control {
    width: calc(100% - 45px);
    border-color: transparent;
  }
  .suggestion__title {
    display: none;
  }
}
.suggestion__close {
  width: 30px;
  height: 30px;
  line-height: 30px;
  font-size: 30px;
  color: #fff;
  text-align: center;
}
.suggestion__body {
  height: calc(100% - 60px);
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}
.suggestion__title {
  width: 100%;
  background-color: #fffcee;
  padding: 15px 20px;
  font-weight: 600;
  border-radius: 5px 5px 0 0;
  color: #222;
  border-top: 2px solid #fa8c16;
}
.suggestion__list {
  margin-bottom: 0;
  border-radius: 6px;
}
.suggestion__list li a {
  font-size: 14px;
  color: #000;
  display: block;
  border-bottom: 1px solid #eee;
  padding: 10px 15px;
  transition: all 0.1s;
}
.suggestion__list li a:hover {
  background: #f5f5f5;
}
.passenger1 {
  position: fixed;
  z-index: 99;
  overflow: hidden;
  transition: all 0.3s ease-in-out;
  visibility: hidden;
}
.passenger1.open,
.passenger1.open .passenger1__main {
  visibility: visible;
}
@media (max-width: 767px) {
  .passenger1.open .passenger1__main {
    transform: translateY(0);
  }
}
@media (min-width: 768px) {
  .passenger1 {
    display: flex;
    justify-content: flex-end;
  }
  .passenger1.open .passenger1__main {
    transform: translateX(0);
  }
}
.passenger1.open .passenger1__backdrop {
  visibility: visible;
  opacity: 1;
}
.passenger1__backdrop,
.passenger1__main {
  transition: all 0.3s ease-in-out;
  visibility: hidden;
}
.passenger1__main {
  position: relative;
  z-index: 10;
  background: #fff;
  height: 100%;
}
@media (max-width: 767px) {
  .passenger1__main {
    transform: translateY(100%);
  }
}
@media (min-width: 768px) {
  .passenger1__main {
    width: 375px;
    transform: translateX(100%);
  }
}
.passenger1__backdrop {
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  opacity: 0;
}
.passenger1__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 60px;
  padding: 0 15px;
  background-color: #fa8c16;
}
.passenger1__header p {
  width: calc(100% - 50px);
  margin-bottom: 0;
  color: #fff;
  font-size: 16px;
  font-weight: 600;
}
.passenger1__close {
  width: 30px;
  height: 30px;
  line-height: 30px;
  font-size: 30px;
  color: #fff;
  text-align: center;
}
.passenger1__body {
  height: calc(100% - 60px);
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}
.passenger1__item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 15px;
  border-bottom: 1px solid #eee;
  height: 55px;
}
.passenger1__item:last-child {
  border-bottom: none;
}
.passenger1__item button.disabled {
  pointer-events: none;
  opacity: 0.3;
}
.passenger1__item button svg {
  display: block;
}
.passenger1__item p {
  margin-bottom: 0;
  text-align: center;
  line-height: 1.3;
}
.passenger1__item p span {
  display: block;
  font-weight: 600;
}
.passenger1__item p i {
  font-size: 10px;
}
.listBtn > p {
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 5px;
}
.listBtn ul li {
  display: inline-block;
  padding: 3px;
}
.listBtn ul li.active button {
  background: #fa8c16;
  color: #fff;
}
.listBtn ul li button {
  background-color: #eee;
  border-radius: 3px;
  padding: 2px 10px;
  font-size: 14px;
  color: #000;
}
.rateStar {
  display: flex;
  margin-bottom: 5px;
}
.listHotel,
.listHotel__option {
  margin-bottom: 30px;
}
.rateStar svg {
  display: block;
}
.rateStar svg + svg {
  margin-left: 2px;
}
.listHotel__option {
  display: flex;
  justify-content: space-between;
  cursor: pointer;
}
.listHotel__option_item {
  border: 1px solid #d9d9d9;
  padding: 12px 34px;
  border-radius: 4px;
  color: #595959;
  text-align: center;
  width: 50%;
  font-size: 14px;
  margin-right: 20px;
}
.listHotel__option_item p {
  font-weight: 600;
  font-size: 16px;
  margin: 0;
}
.listHotel__option_item .hotel_name_mobile {
  display: none;
}
.listHotel__option_item:last-child {
  margin-right: 0;
}
.listHotel__option_item.active {
  background: #fa8c16;
  border: 1px solid #fa8c16;
  color: #fff;
}
@media (max-width: 767px) {
  .listHotel__option_item .hotel_name {
    display: none;
  }
  .listHotel__option_item .hotel_name_mobile {
    display: block;
  }
  .listHotel__option_item {
    margin-right: 8px;
    padding: 8px;
  }
  .listHotel__option_item p {
    font-size: 14px;
  }
  .listHotel__option_item span {
    font-size: 12px;
  }
}
@media (max-width: 320px) {
  .listHotel__option_item {
    padding: 8px 0;
    font-size: 13px;
  }
  .listHotel__option_item p {
    font-size: 13px;
  }
}
.listHotel__row {
  padding-bottom: 15px;
}
.listHotel__row .radio label {
  width: 100%;
}
.listHotel__row .radio input[type="radio"]:checked + label:before {
  border: 3px solid #fff;
  box-shadow: 0 0 0 1px #fa8c16;
  background: #fa8c16;
}
.listHotel__row .radio input[type="radio"] + label:before {
  top: 50%;
  transform: translateY(-50%);
}
@media (max-width: 767px) {
  .listHotel__row .radio label {
    padding: 0;
  }
  .listHotel__row .radio input[type="radio"] + label:before {
    display: none;
  }
  .listHotel__row .radio input[type="radio"] + label .hotelItem {
    border: 1px solid #d9d9d9;
  }
  .listHotel__row .radio input[type="radio"]:checked + label .hotelItem {
    border: 1px solid #fa8c16;
  }
  .listHotel__row
    .radio
    input[type="radio"]:checked
    + label
    .hotelItem
    .icon-check {
    display: block;
  }
}
.listHotel__row .radio input[type="radio"]:checked + label .hotelItem {
  border: 1px solid #fa8c16;
}
.comboInfo,
.hotelItem {
  border: 1px solid #eee;
}
.comboFlight__item,
.comboInfo__detail,
.comboInfo__guest,
.formPay__option__group {
  border-bottom: 1px solid #eee;
}
.hotelItem {
  display: flex;
  border-radius: 4px;
  overflow: hidden;
  position: relative;
}
.hotelItem__img {
  width: 104px;
  min-height: 112px;
  border-radius: 4px;
}
.hotelItem__img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 4px 0 0 4px;
}
.hotelItem__cont {
  width: calc(100% - 70px);
  padding: 5px 15px;
}
@media (max-width: 767px) {
  .hotelItem {
    overflow: inherit;
  }
  .hotelItem__cont {
    padding: 5px 5px 5px 15px;
  }
}
.hotelItem__name {
  font-size: 14px;
  color: #000;
  font-weight: 600;
}
.hotelItem__add {
  font-size: 12px;
  color: #666;
  display: flex;
  align-items: center;
}
.hotelItem__btn {
  margin-bottom: 10px;
}
.hotelItem__btn li {
  display: inline-block;
}
.hotelItem__btn li + li {
  margin-left: 10px;
}
.hotelItem__btn li button {
  background-color: #fff7e6;
  border-radius: 3px;
  padding: 2px 10px;
  font-size: 12px;
  color: #262626;
}
@media (max-width: 767px) {
  .hotelItem__btn {
    text-align: left;
  }
  .hotelItem__btn li + li {
    margin-left: 5px;
  }
}
.hotelItem .icon-check {
  display: none;
  position: absolute;
  right: -8px;
  z-index: 6;
  width: 16px;
  top: -8px;
  background: #fff;
  border-radius: 50%;
  height: 16px;
}
.comboInfo {
  padding: 15px;
  border-radius: 3px;
  margin-bottom: 15px;
}
.comboInfo__name {
  font-size: 16px;
  color: #000;
  font-weight: 600;
  margin-bottom: 15px;
}
.comboInfo__detail {
  padding-bottom: 10px;
  margin-bottom: 15px;
}
.comboInfo__detail li {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 5px;
}
.comboInfo__detail li p {
  font-size: 14px;
  color: #000;
  margin-bottom: 0;
}
.comboInfo__detail li p:first-child {
  color: #666;
}
.comboInfo__detail li p:first-child svg {
  margin-right: 10px;
}
.comboInfo__detail li p:first-child svg path {
  fill: #666;
}
.comboInfo__guest {
  padding-bottom: 10px;
  margin-bottom: 15px;
}
.comboInfo__guest li p,
.comboInfo__price p {
  color: #000;
  margin-bottom: 0;
  font-weight: 600;
}
.comboInfo__guest li {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 5px;
}
.comboInfo__guest li p {
  font-size: 14px;
}
.comboInfo__price .d-flex {
  justify-content: space-between;
  align-items: center;
}
.comboInfo__price p {
  font-size: 18px;
}
.comboInfo__price p:last-child {
  color: #fa8c16;
}
.comboInfo__fee {
  font-size: 14px;
  color: #666;
}
.comboFlight {
  padding: 15px;
  border: 1px solid #eee;
  border-radius: 3px;
  margin-bottom: 15px;
}
.comboFlight__item {
  padding-bottom: 10px;
  margin-bottom: 15px;
}
.comboFlight__item ul li {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 5px;
}
.comboFlight__item ul li p {
  margin-bottom: 0;
  font-size: 14px;
  color: #000;
}
.comboFlight__change {
  display: flex;
  align-items: center;
}
.comboFlight__change a {
  background-color: #eee;
  border-radius: 3px;
  padding: 2px 10px;
  font-size: 12px;
  color: #000;
  white-space: nowrap;
}
.comboFlight__change p {
  font-style: italic;
  color: #666;
  font-size: 12px;
  padding-left: 15px;
  margin-bottom: 0;
}
.comboHotel,
.passengerInfo {
  padding: 15px;
  border: 1px solid #eee;
  border-radius: 3px;
  margin-bottom: 15px;
}
.passengerInfo__note {
  margin-bottom: 15px;
}
.passengerInfo__note p {
  font-size: 14px;
  color: #666;
  margin-bottom: 0;
}
.passengerInfo__list {
  padding-bottom: 10px;
}
.passengerInfo__list li {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 5px;
}
.passengerInfo__list li p {
  font-size: 14px;
  margin-bottom: 0;
  color: #000;
}
.passengerInfo__list li p:last-child {
  font-weight: 600;
}
.passengerInfo__row {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -7.5px;
}
.passengerInfo__row.row-1 .form-group {
  flex: auto;
}
.passengerInfo__row.row-1 .form-group:first-child {
  width: 150px;
}
.passengerInfo__row.row-1 .form-group:last-child {
  width: calc(100% - 150px);
}
.passengerInfo__row.row-2 .form-group {
  flex: auto;
}
.passengerInfo__row.row-2 .form-group:nth-child(1) {
  width: 150px;
}
.passengerInfo__row.row-2 .form-group:nth-child(2) {
  width: calc(100% - 150px);
}
@media (min-width: 768px) {
  .passengerInfo__row.row-2 .form-group:nth-child(2) {
    width: calc((100% - 150px) / 2);
  }
}
.passengerInfo__row.row-2 .form-group:nth-child(3) {
  width: 100%;
}
@media (min-width: 768px) {
  .passengerInfo__row.row-2 .form-group:nth-child(3) {
    width: calc((100% - 150px) / 2);
  }
}
.passengerInfo__row .form-group {
  flex: 1;
  padding: 0 7.5px;
}
.formPay {
  padding: 15px 0;
}
.formPay__option {
  margin-bottom: 20px;
}
.formPay__option__group {
  padding: 10px 0;
}
.formPay__option .radio label {
  display: flex;
  align-items: center;
  height: auto;
}
.formPay__option .radio input[type="radio"] + label:before {
  top: 50%;
  transform: translateY(-50%);
}
.formPay__option .radio__icon {
  width: 45px;
}
.formPay__option .radio__text {
  padding-left: 15px;
  width: calc(100% - 45px);
}
.formPay__option .radio__text p {
  margin-bottom: 0;
  font-size: 14px;
  color: #000;
}
.formPay__option .radio__text span {
  font-size: 12px;
  color: rgba(34, 34, 34, 0.7);
}
.calendar {
  position: relative;
}
.calendar .loadingItem {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
}
.loadingItem {
  margin: 15px 0;
}
.checkoutStatus__contact {
  display: flex;
  justify-content: center;
  margin: 15px -15px;
}
.checkoutStatus__contact .d-item {
  padding: 0 15px;
}
.checkoutStatus__contact .d-item span {
  font-size: 16px;
  font-weight: 600;
}
.checkoutStatus__contact .d-item span:first-child {
  color: #fa8c16;
}
.checkoutStatus__contact .d-item span:last-child {
  color: #262626;
}
.checkoutStatus__input {
  display: flex;
  margin-bottom: 15px;
}
.checkoutStatus__input .btn,
.checkoutStatus__input .form-control {
  height: 35px;
}
.checkoutStatus__input .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.checkoutStatus__input .form-control {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-right: none;
}
.checkoutStatus__code {
  text-align: center;
  margin: 15px 0;
}
.checkoutStatus__flight {
  text-align: center;
}
.checkoutStatus__flight p {
  font-size: 14px;
  color: #262626;
  font-weight: 600;
  margin-bottom: 15px;
}
.checkoutStatus__cont h4 {
  font-size: 20px;
  color: #fa8c16;
  text-align: center;
  margin-bottom: 15px;
}
.checkoutStatus__cont > p {
  font-size: 14px;
  color: #262626;
  text-align: center;
  font-weight: 600;
}
@media (min-width: 768px) {
  .hidden-sm {
    display: none;
  }
}
@media (max-width: 767px) {
  .hidden-xs {
    display: none;
  }
  .visible-xs {
    display: block;
  }
  .bookingCode {
    width: 100%;
  }
}
.bookingCode {
  display: inline-flex;
  justify-content: center;
  background-color: #fa8c16;
  padding: 2px;
}
.bookingCode__title {
  color: #fff;
  padding: 5px 15px;
}
.bookingCode__code {
  flex: 1;
  background-color: #fff;
  padding: 5px 15px;
  font-size: 16px;
  color: #262626;
  font-weight: 600;
}
.promotion {
  background: #fafafa;
}
.promotion_hot__title {
  text-align: center;
  padding: 40px 10px 30px;
}
.promotion_hot__title h2 {
  font-weight: 700;
  font-size: 24px;
  color: #262626;
  margin: 0;
}
.promotion .achievement {
  width: 100%;
  text-align: center;
  padding: 0 0 30px;
}
.promotion .achievement__item {
  padding: 0 10px;
  cursor: auto;
}
.promotion .achievement__img {
  line-height: 60px;
  margin-bottom: 10px;
}
.promotion .achievement__img .sprite-logo_top10 {
  width: 54px;
  height: 54px;
}
.promotion .sprite-logo {
  background-image: url(https://statics.vntrip.vn/images/sprite/sprite-logo.png);
  background-repeat: no-repeat;
  margin: auto;
}
.promotion .sprite-logo_top10 {
  width: 54px !important;
  height: 54px !important;
}
.promotion .sprite-logo_gold {
  width: 85px !important;
  height: 55px !important;
  background-position: -54px 0;
}
.promotion .sprite-logo_appell {
  width: 55px !important;
  height: 55px !important;
  background-position: -139px 0;
}
.promotion .sprite-logo_prize1 {
  width: 42px !important;
  height: 55px !important;
  background-position: -194px 0;
}
.promotion .sprite-logo_prize2 {
  width: 66px !important;
  height: 55px !important;
  background-position: -237px 0;
}
.promotion .sprite-logo_honors {
  width: 57px !important;
  height: 55px !important;
  background-position: -301px 0;
}
.promotion .sprite-logo_awards {
  width: 148px !important;
  height: 55px !important;
  background-position: -366px 0;
}
.promotion .sprite-logo_vtv {
  width: 90px;
  height: 33px;
  background-position: 0 -73px;
}
.promotion .sprite-logo_vnexpress {
  width: 131px;
  height: 30px;
  background-position: -95px -74px;
}
.promotion .sprite-logo_cafebiz {
  width: 101px;
  height: 31px;
  background-position: -229px -74px;
}
.promotion .sprite-logo_deal {
  width: 62px;
  height: 60px;
  background-position: -333px -60px;
}
.promotion .sprite-logo_times {
  width: 102px;
  height: 29px;
  background-position: -398px -75px;
}
.promotion .sprite-logo_banks {
  width: 101px;
  height: 29px;
  background-position: -504px -75px;
}
.promotion .articles {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  padding: 0 0 30px;
}
.promotion .articles__item {
  width: calc(100% / 6);
  text-align: center;
  margin: 15px 0;
}
@media (max-width: 991px) {
  .promotion .articles__item {
    width: 50%;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .promotion .articles__item {
    width: calc(100% / 5);
  }
}
