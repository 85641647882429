.btn-san-ve:hover{
    outline: none;
    border: none;
}
.btn-san-ve:focus{
    outline: none;
    border: none;
}

.modalDateFlight .btn-search{
    display: flex;
    justify-content: flex-end;
}


/* start form search */
.formSearch .formInput{
   margin: 0 -8px;
}
.formSearch .inputItem{
    width: calc(100% / 3);
    padding: 0 8px;
}
.formSearch .ant-input-affix-wrapper, .formSearch .btnPassenger {
    height: 56px;
}
.dropdown.dropdown-open .dropdown-menu, .dropdown.show .dropdown-menu {
    display: block;
}
.formSearch .ant-drawer-content,.formSearch .ant-drawer-body{
    overflow: inherit;
}
.formSearch .passengerBox__item {
    display: -moz-box;
    display: flex;
    -moz-box-pack: justify;
    justify-content: space-between;
    -moz-box-align: center;
    align-items: center;
    cursor: auto;
    padding: 10px 15px;
}
.formSearch .passengerBox__item
.formSearch .passengerBox__minus, .passengerBox__plus{
    background-color: #eee;
}
.formSearch .ant-btn-text:hover, .ant-btn-text:focus{
    background-color: #eee;
    opacity: 0.8;
}

.formSearch .ant-btn-text[disabled],.formSearch .ant-btn-text[disabled]:active,.formSearch .ant-btn-text[disabled]:focus,.formSearch .ant-btn-text[disabled]:hover{
    color: rgba(0,0,0,.25);
    background: transparent;
    border-color: transparent;
    text-shadow: none;
    box-shadow: none;
}
/* end form search */