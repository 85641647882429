.btn-primary-blue{
  background: #40A9FF;
  border-color: #40A9FF;
  color: #fff;
  &:hover{
    background: #40A9FF;
    opacity: 0.8;
    color: #fff;
  }
  &:focus{
    outline: none;
    color: #fff;
    background: #40A9FF;
    border-color: #40A9FF;
  }
}
.btn-primary-outline {
  background: #FFF7E6!important;
  border: none!important;
  border-radius: 4px;
  color: #FF8917!important;
  box-shadow: none!important;
  text-shadow: none;
  &:hover{
    background: #FFF7E6!important;
    opacity: 0.8;
    color: #FF8917!important;
    border: none!important;
    box-shadow: none!important;
  }
  &:focus{
    outline: none;
    color: #FF8917!important;
    background: #FFF7E6!important;
    border: none!important;
    box-shadow: none!important;
  }
}

.btn-primary-border{
  border: 1px solid #FFF7E6!important;
}