 /*css trang home*/
.btn.btn-san-ve {
    background: var(--color-primary)
}
.vnt-radio input[type=radio]:checked+label:before {
    box-shadow: 0 0 0 1px var(--color-primary);
    background: var(--color-primary);
}

.list-tip-combo .tip-combo-item svg path{
    fill: var(--color-primary);
}

.comments__slide .slick-dots li.slick-active button{
    background: var(--color-primary);
}
.form-box-search .box-search-item .combo-item-content{
    border: 1px solid var(--color-primary);
}
 .suggestion__title{
     border-top: 2px solid var(--color-primary);
 }
 @media (max-width: 991px){
     .suggestion__header{
         background-color: var(--color-primary);
     }
     .form-box-search .box-search-item .passenger .passenger__body .passenger_header {
         background-color: var(--color-primary);
     }
 }

 .v-loading-page__dot {
     background-color: var(--color-primary);
 }
 .vntLoading .v-loading-page__dot {
     background-color: var(--color-primary);
 }
 /* end css trang home*/


 /* start css trang flights*/
 .DayPicker:not(.DayPicker--interactionDisabled) .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):hover{
     color: var(--color-primary);

 }
 .DayPicker:not(.DayPicker--interactionDisabled) .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):hover span{
     color: var(--color-primary);
 }
 .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside){
     color: var(--color-primary)!important;
     background: none;
 }
 .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) span{
     color: var(--color-primary)!important;
 }

 .btn.btn_orange{
     background: var(--color-primary);
 }
 .listBtn ul li.active button{
     background: var(--color-primary);
 }
 .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside):hover {
     color: var(--color-primary)!important;
     background: none;
 }
 /* end css trang flights*/


 /* start css trang hotels*/
 .listHotel__option_item.active{
     background: var(--color-primary);
     border: 1px solid var(--color-primary);
 }
 .hotelItem .icon-check svg  path{
     fill: var(--color-primary);
 }
 .listHotel__row .radio input[type=radio]:checked+label .hotelItem{
     border: 1px solid var(--color-primary);
 }
 .hotelReview__info__totalPrice .d-flex p.price{
     color: var(--color-primary);
 }
 .hotelReview__info .title-hotel h2{
     border-bottom: 1px solid var(--color-primary);
 }
 .ant-modal-wrap.modal-gallery .image-gallery-left-nav .image-gallery-svg, .ant-modal-wrap.modal-gallery .image-gallery-right-nav .image-gallery-svg{
     stroke: var(--color-primary)
 }
 .ant-modal-wrap.modal-gallery .image-gallery-thumbnail.active{
     border: 3px solid var(--color-primary);
 }
 .title1 h4{
     border-bottom: 1px solid var(--color-primary);
 }
 .listHotel__row .radio input[type=radio]:checked+label:before{
     box-shadow: 0 0 0 1px var(--color-primary);
     background: var(--color-primary);
 }
 /* end css trang hotels*/
 /* start css trang payment method*/
 .step6 .radio input[type=radio]:checked+label:before {
     box-shadow: 0 0 0 1px var(--color-primary);
     background: var(--color-primary);
 }
 /* end css trang payment method*/

 .btn.btn_disable{
     background: #ccc;
 }
 .btn_round{
     background: var(--color-primary);
     color: #fff;
 }
 .passengerInfo .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle, .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before{
     border-bottom-color: var(--color-primary)!important;
 }
 .passengerInfo .react-datepicker__header {
     background: var(--color-primary) !important;
 }
 .passengerInfo .react-datepicker__day--keyboard-selected, .passengerInfo .react-datepicker__day--selected {
     background: var(--color-primary) !important;
 }
 .btn__orange{
     background: var(--color-primary) !important;
     border: 1px solid var(--color-primary) !important;
 }
 .btn__orange:hover{
     background: var(--color-primary) !important;
     border: 1px solid var(--color-primary) !important;
     opacity: 0.8;
 }
 .btn__orange:focus{
     background: var(--color-primary) !important;
     border: 1px solid var(--color-primary) !important;
 }
 .bookingCode{
     /*background-color: var(--color-primary) !important;*/
     border: 2px solid var(--color-primary) !important;
 }

 /*.ant-btn-primary, .ant-btn-primary:focus, .ant-btn-primary:hover{*/
 /*    background-color: var(--color-primary) !important;*/
 /*    border-color: var(--color-primary) !important;*/
 /*}*/
 .btn.btn_outlineOrange{
     color: black;
     background-color: transparent!important;
     border-color: var(--color-primary) !important;
 }
 .ant-btn-primary[disabled], .ant-btn-primary[disabled]:hover, .ant-btn-primary[disabled]:focus, .ant-btn-primary[disabled]:active{
     color: rgba(0, 0, 0, 0.25)!important;
     border-color: #d9d9d9!important;
     background: #f5f5f5!important;
 }