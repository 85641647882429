@import '~antd/dist/antd.css';
@import "frontend.min.css";
.step3 .note{
    display: flex;
}
.step3 .note__item{
    margin-right: 20px;
    display: flex;
    align-items: center;
}
.step3 .note__item:last-child{
    margin-right: 0;
}
.step3 .note__item .icon{
    width: 35px;
    height: 35px;
    border-radius: 4px;
    background: #D9F7BE;
    margin-right: 5px;
}
.step3 .note__item .icon.orange{
    background: #FFE7BA;
}
.step3 .note__item .icon.pink{
    background: #FFCCC7;
}
.step3 .note__item .text{
    font-size: 12px;
}

@media (max-width: 767px) {
    .step3 .note__item{
        margin-right: 5px;
    }
    .step3 .note__item .icon{
        width: 25px;
        height: 25px;
    }
    body.noScroll {
        overflow: hidden
    }
}

/*/// btn ///*/
.btn {
    line-height: 1.5;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    white-space: nowrap;
    background-image: none;
    border: 1px solid transparent;
    box-shadow: 0 2px 0 rgba(0, 0, 0, .015);
    cursor: pointer;
    transition: all .2s;
    user-select: none;
    touch-action: manipulation;
    padding: 0 15px;
    border-radius: 4px
}

.btn svg {
    margin-right: 5px
}

.btn:hover {
    opacity: .9
}

.btn.btn_orange {
    color: #fff;
    background-color: #ff8917;
    border-color: #ff8917
}

.btn.btn_blue {
    color: #fff;
    background-color: #1890FF;
    border-color: #1890FF
}

.btn.btn_green {
    color: #fff;
    background-color: #389e0d;
    border-color: #389e0d
}

.btn.btn_outlineOrange {
    color: #ff8917;
    background-color: #fff;
    border-color: #ff8917
}

.btn.btn_outlineRed {
    color: #FF4D4F;
    background-color: #fff;
    border-color: #FF4D4F
}

.btn.btn_outlineBlue {
    color: #1890FF;
    background-color: #fff;
    border-color: #1890FF
}

.btn.btn_light {
    background-color: #f2f2f2
}

.btn.btn_white {
    background-color: #fff;
    color: #ff8917
}

.btn.btn_black {
    background-color: rgba(46, 58, 89, .7);
    color: #fff
}

.btn.btn_outline {
    color: #878787;
    background-color: #fff;
    border-color: #d9d9d9
}

.btn.btn_xs {
    height: 24px;
    font-size: 12px
}

.btn.btn_sm {
    height: 32px;
    font-size: 12px
}

.btn.btn_md {
    height: 40px;
    font-size: 16px
}

.btn.btn_lg {
    height: 48px;
    font-size: 16px
}

.btn.btn_disabled, .btn.btn_loading {
    pointer-events: none;
    cursor: default;
    opacity: .5
}

.btn.btn_loading .fa-spinner {
    animation: spin 1.5s linear infinite
}

.ant-btn-primary, .ant-btn-primary:focus, .ant-btn-primary:hover {
    background-color: #ff8917;
    border-color: #ff8917;
    color: #fff;
}


/*** header ***/
.header__menu .dropdown-toggle img {
    width: 30px;
    height: 30px;
}
.header__menu .dropdown-toggle{
    border: none;
    padding: 0;
}
.dropdown-toggle {
    color: #000;
    border: 1px solid #d9d9d9;
    background: #fff;
    border-radius: 4px;
    width: 100%;
    display: -webkit-inline-flex;
    display: -moz-inline-box;
    display: inline-flex;
    -webkit-align-items: center;
    -moz-box-align: center;
    align-items: center;
    min-height: 40px;
    text-align: left;
    padding: 5px 15px;
    -webkit-transition: all .3s;
    -moz-transition: all .3s;
    transition: all .3s;
}
.header__menu .dropdown-toggle p span.span1 {
    color: #1890ff;
}

 /** header mobile **/

.header__group {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 60px;
    padding: 0 15px
}
.header__logo .logo {
    display: block;
    font-size: 0;
}
.header__logo .logo svg {
    width: 100px;
}
@media (max-width: 767px) {
    .header__logo {
        margin-left: 15px;
        margin-bottom: 0px;
    }

    .header__group {
        position: relative
    }
}
@media (max-width: 991px) {
    .header__desktop {
        display: none
    }
}

@media (min-width: 992px) {
    .header__mobile {
        display: none
    }
}

/** menu desktop **/
.menuDesktop{
    border-radius: 4px;
}

.menuDesktop__body {
    padding: 10px 15px;
}

.menuDesktop__header, .menuDesktop__list>li {
    border-bottom: 1px solid #d9d9d9;
}

.menuDesktop__list>li>a {
    width: 100%;
    display: -webkit-flex;
    display: -moz-box;
    display: flex;
    -webkit-align-items: center;
    -moz-box-align: center;
    align-items: center;
    color: #000;
    padding: 10px 0;
    -webkit-transition: all .2s;
    -moz-transition: all .2s;
    transition: all .2s;
}
.menuDesktop__list>li>a>svg {
    width: 20px;
    margin-right: 15px;
}
.menuDesktop__list>li>a>span {
    -webkit-flex: 1 1;
    -moz-box-flex: 1;
    flex: 1 1;
}

/** menu mobile **/

.menuMobile .currency .dropdown-menu, .menuMobile .language .dropdown-menu {
    box-shadow: none;
    border-top: 1px solid #eee;
    position: initial;
    width: 100%
}

.currency .dropdown-toggle .svgArrow, .header__menu .dropdown-toggle .svgArrow, .language .dropdown-toggle .svgArrow, .menuMobile {
    right: 0
}

.menuMobile .language .dropdown-toggle:hover, .menuMobile__list > li > a:hover, .menuMobile__list > li > button:hover {
    text-decoration: none
}


.menuMobile__header, .menuMobile__list {
    border-bottom: 6px solid #eee
}

.menuMobile {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    z-index: 99;
    transition: all .3s ease-in-out;
    visibility: hidden
}

@media (min-width: 992px) {
    .menuMobile {
        display: none
    }
}

.menuMobile.open {
    visibility: visible
}

.menuMobile.open .menuMobile__cont {
    transform: none;
    visibility: visible
}

.menuMobile.open .menuMobile__backdrop {
    visibility: visible;
    opacity: 1
}

.menuMobile__backdrop, .menuMobile__cont {
    transition: all .3s ease-in-out;
    visibility: hidden
}

.menuMobile__cont {
    width: calc(100% - 50px);
    height: 100%;
    position: relative;
    background-color: #fff;
    overflow-y: auto;
    overflow-x: hidden;
    -webkit-overflow-scrolling: touch;
    transform: translateX(-100%)
}

.menuMobile__header {
    display: flex;
    align-items: center;
    padding: 15px
}

.menuMobile__right {
    width: calc(100% - 50px);
    padding-left: 15px
}

.menuMobile__info p {
    margin-bottom: 0;
    font-size: 12px
}

.menuMobile__info p.p2 {
    font-size: 16px;
    font-weight: 600
}

.menuMobile__info p.p3 {
    color: #8c8c8c
}

.menuMobile__avatar {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    overflow: hidden
}

.menuMobile__avatar img {
    width: 100%;
    height: 100%;
    object-fit: cover
}

.menuMobile__btn {
    text-align: center
}

.menuMobile__btn .btn {
    margin: 2.5px
}

.menuMobile__list:last-child, .menuMobile__list > li:last-child > a, .menuMobile__list > li:last-child > button {
    border-bottom: none
}

.menuMobile .currency, .menuMobile .language, .menuMobile__switch {
    border-bottom: 1px solid #eee
}

.menuMobile__list > li > a, .menuMobile__list > li > button {
    width: 100%;
    display: flex;
    align-items: center;
    font-size: 14px;
    color: #000;
    padding: 10px 15px;
    border-bottom: 1px solid #eee
}

.menuMobile .language__link, .menuMobile__switch {
    justify-content: space-between
}

.menuMobile__list > li > a > svg, .menuMobile__list > li > button > svg {
    width: 18px;
    margin-right: 15px
}

.menuMobile__list .checkbox {
    padding: 10px 15px
}

.menuMobile__backdrop {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .7);
    z-index: -1;
    opacity: 0
}

.menuMobile__switch {
    display: flex;
    align-items: center;
    font-size: 14px;
    color: #000;
    padding: 10px 15px
}

.menuMobile__switch .dText {
    display: flex;
    align-items: center
}

.menuMobile__switch .dText svg {
    width: 18px;
    margin-right: 15px
}

.menuMobile__switch .dText svg path {
    fill: #000
}

.menuMobile__logout {
    padding: 15px
}

.menuMobile__logout .btn {
    width: 100%;
    font-weight: 600
}

.menuMobile .language__link svg {
    order: 2
}

.menuMobile .language__link span {
    order: 1;
    margin-left: 0
}

.menuMobile .language .dropdown-toggle {
    padding: 10px 15px;
    color: #000;
    font-weight: 400;
    justify-content: space-between
}

.menuMobile .language .dropdown-toggle svg {
    order: 2
}

.menuMobile .language .dropdown-toggle span {
    order: 1;
    margin-left: 0
}

.menuMobile .language .dropdown-toggle .svgArrow {
    display: none
}

.menuMobile .currency .dropdown-toggle {
    padding: 10px 15px;
    border-radius: 0;
    font-weight: 400
}

.menuMobile .currency .dropdown-toggle .svgArrow {
    right: 15px
}

