.ant-drawer{
  @include breakpoint(max-sm){
    &.ant-drawer-open {
      width: 100%;
    }
  }
  .ant-drawer-content-wrapper{
    @include breakpoint(max-sm){
      width: 100% !important;
    }
  }
}