@import 'variables';
.pageBody {
    overflow: hidden;
    &.heightPage100{
        height: 100%;
        .ant-spin-nested-loading, .ant-spin-container, .pageWapper, main{
            height: 100%;
        }
    }
}
.pageWapper {
    background-color: #191623;
}

.text-lively {
    background: linear-gradient(93.2deg, #ffc204 -32.19%, #ff057d 125.11%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.text-disabled {
    background: linear-gradient(93.2deg, #867f96 -32.19%, #867f96 125.11%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.maxH-0 {
    max-height: 0 !important;
}

.hidden {
    display: none !important;
}

.show {
    display: block !important;
}

.d-table {
    display: table !important;
}

.d-cell {
    display: table-cell !important;
}

.d-block {
    display: block !important;
}

.d-inline {
    display: inline !important;
}

.d-inline-block {
    display: inline-block !important;
}

.d-flex {
    display: flex ;
}

.text-left {
    text-align: left !important;
}

.text-center {
    text-align: center !important;
}

.text-right {
    text-align: right !important;
}

.italic {
    font-style: italic !important;
}

.regular {
    font-weight: 400 !important;
}

.medium {
    font-weight: 500 !important;
}

.semibold {
    font-weight: 600 !important;
}

.bold {
    font-weight: 700 !important;
}

.float-left {
    float: left !important;
}

.float-right {
    float: right !important;
}

.float-none {
    float: none !important;
}

.size-10 {
    font-size: 10px !important;
}

.size-11 {
    font-size: 11px !important;
}

.size-12 {
    font-size: $size-12 !important;
}

.size-13 {
    font-size: 13px !important;
}

.size-14 {
    font-size: 14px !important;
}

.size-16 {
    font-size: 16px !important;
}

.size-18 {
    font-size: 18px !important;
}

.size-20 {
    font-size: 20px !important;
}

.size-22 {
    font-size: 22px !important;
}

.size-24 {
    font-size: 24px !important;
}

.size-26 {
    font-size: 26px !important;
}

.size-28 {
    font-size: 28px !important;
}

.size-30 {
    font-size: 30px !important;
}

.size-32 {
    font-size: 32px !important;
}

.size-35 {
    font-size: 35px !important;
}

.size-40 {
    font-size: 40px !important;
}

.size-50 {
    font-size: 50px !important;
}

.white-1 {
    color: #fff !important;
}

.white-2 {
    color: #cbc4d8 !important;
}

.purple-1 {
    color: #96b4ff !important;
}

.black-1 {
    color: #000 !important;
}

.yellow-1 {
    color: #fa8c16 !important;
}

.blue-1 {
    color: #40a9ff !important;
}

.blue{
    color: #1890FF;
}

.red-1 {
    color: #f5222d !important;
}

.green-1 {
    color: #52d001 !important;
}

.gray-1 {
    color: #f1f1f1 !important;
}
.gray-2 {
    color: #f2f2f2 !important;
}
.gray-3 {
    color: #cccccc !important;
}
.gray-4 {
    color: $gray-13 !important;
}

.gray-5 {
    color: $gray-5 !important;
}

.gray-6 {
    color: #867f96 !important;
}

.p-12 {
    padding: 12px !important;
}

.p-16 {
    padding: 16px !important;
}

.p-30 {
    padding: 30px !important;
}

.p-18 {
    padding: 18px !important;
}

.ptb-12 {
    padding: 12px 0 12px 0 !important;
}

.ptb-16 {
    padding: 16px 0 16px 0 !important;
}

.ptb-4 {
    padding: 4px 0 4px 0 !important;
}

.ptb-6 {
    padding: 6px 0 6px 0 !important;
}

.p-8 {
    padding: 8px !important;
}

.p-0 {
    padding: 0 !important;
}

.p-15 {
    padding: 15px !important;
}

.p-20 {
    padding: 20px !important;
}

.pt-0 {
    padding-top: 0 !important;
}

.pt-5 {
    padding-top: 5px !important;
}

.pt-10 {
    padding-top: 10px !important;
}

.pt-15 {
    padding-top: 15px !important;
}

.pt-20 {
    padding-top: 20px !important;
}

.pt-25 {
    padding-top: 25px !important;
}

.pt-30 {
    padding-top: 30px !important;
}

.pt-60 {
    padding-top: 60px !important;
}

.pb-0 {
    padding-bottom: 0 !important;
}

.pb-5 {
    padding-bottom: 5px !important;
}

.pb-10 {
    padding-bottom: 10px !important;
}

.pb-15 {
    padding-bottom: 15px !important;
}

.pb-20 {
    padding-bottom: 20px !important;
}

.pb-25 {
    padding-bottom: 25px !important;
}

.pb-30 {
    padding-bottom: 30px !important;
}

.pl-0 {
    padding-left: 0 !important;
}

.pl-5 {
    padding-left: 5px !important;
}

.pl-10 {
    padding-left: 10px !important;
}

.pl-15 {
    padding-left: 15px !important;
}

.pl-20 {
    padding-left: 20px !important;
}

.pl-25 {
    padding-left: 25px !important;
}

.pl-30 {
    padding-left: 30px !important;
}

.pl-40 {
    padding-left: 40px !important;
}

.pr-0 {
    padding-right: 0 !important;
}

.pr-5 {
    padding-right: 5px !important;
}

.pr-10 {
    padding-right: 10px !important;
}

.pr-15 {
    padding-right: 15px !important;
}

.pr-20 {
    padding-right: 20px !important;
}

.pr-25 {
    padding-right: 25px !important;
}

.pr-30 {
    padding-right: 30px !important;
}

.pr-40 {
    padding-right: 40px !important;
}

.mt-0 {
    margin-top: 0 !important;
}

.mt-5 {
    margin-top: 5px !important;
}

.mt-10 {
    margin-top: 10px !important;
}

.mt-15 {
    margin-top: 15px !important;
}

.mt-20 {
    margin-top: 20px !important;
}

.mt-22 {
    margin-top: 22px !important;
}

.mt-25 {
    margin-top: 25px !important;
}

.mt-30 {
    margin-top: 30px !important;
}

.mt-40 {
    margin-top: 40px !important;
}

.mt-50 {
    margin-top: 50px !important;
}

.mt-90 {
    margin-top: 90px !important;
}

.mb-0 {
    margin-bottom: 0 !important;
}

.mb-5 {
    margin-bottom: 5px !important;
}

.mb-10 {
    margin-bottom: 10px !important;
}

.mb-15 {
    margin-bottom: 15px !important;
}

.mb-16 {
    margin-bottom: 16px !important;
}

.mb-20 {
    margin-bottom: 20px !important;
}

.mb-25 {
    margin-bottom: 25px !important;
}

.mb-30 {
    margin-bottom: 30px !important;
}

.mb-40 {
    margin-bottom: 40px !important;
}

.mb-50 {
    margin-bottom: 50px !important;
}

.mb-70 {
    margin-bottom: 70px !important;
}

.ml-0 {
    margin-left: 0 !important;
}

.ml-5 {
    margin-left: 5px !important;
}

.ml-10 {
    margin-left: 10px !important;
}

.ml-15 {
    margin-left: 15px !important;
}

.ml-20 {
    margin-left: 20px !important;
}

.ml-25 {
    margin-left: 25px !important;
}

.ml-30 {
    margin-left: 30px !important;
}

.ml-40 {
    margin-left: 40px !important;
}

.ml-45 {
    margin-left: 45px !important;
}

.mr-0 {
    margin-right: 0 !important;
}

.mr-5 {
    margin-right: 5px !important;
}

.mr-10 {
    margin-right: 10px !important;
}

.ml--5 {
    margin-left: -5px !important;
}

.mr-15 {
    margin-right: 15px !important;
}

.mr-20 {
    margin-right: 20px !important;
}

.mr-25 {
    margin-right: 25px !important;
}

.mr-30 {
    margin-right: 30px !important;
}

.mr-40 {
    margin-right: 40px !important;
}

.w-10 {
    width: 10% !important;
}

.w-20 {
    width: 20% !important;
}

.w-25 {
    width: 25% !important;
}

.w-40 {
    width: 40% !important;
}

.w-50 {
    width: 50% !important;
}

.w-75 {
    width: 75% !important;
}

.w-100 {
    width: 100% !important;
}

.w100 {
    width: 100px !important;
}

.h-25 {
    height: 25% !important;
}

.h-50 {
    height: 50% !important;
}

.h-75 {
    height: 75% !important;
}

.h-100 {
    height: 100% !important;
}

.o-hidden {
    overflow: hidden !important;
}

.cursor-pointer {
    cursor: pointer;
}

.dFlex {
    display: flex;
}
.text-right{
    text-align: right;
}
.dFlexColumn {
    display: flex;
    flex-direction: column;
}

.dFlexColumnCenter {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.flexCenter {
    display: flex;
    align-items: center;
    justify-content: center;
}

.flexCenter2 {
    display: flex;
    justify-content: center;
}

.flexGroup {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.flexGroup2 {
    display: flex;
    align-items: center;
}

.flexGroup3 {
    display: flex;
    justify-content: flex-end;
}

.flexGroup4 {
    display: flex;
    justify-content: space-between;
}
.flexGroup5 {
    display: flex;
    flex-wrap: wrap;
}
.flexGroup6 {
    display: flex;
    align-items: flex-start;
}

.flexGroup7 {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.dFlexInline {
    display: inline-block !important;
}

.pointer {
    cursor: pointer !important;
}

.solid {
    width: 100%;
    border-bottom: 1px solid #d9d9d9;
    margin: 10px 0;
}

.relative {
    position: relative !important;
}

.absolute {
    position: absolute !important;
}

.disc ul {
    list-style: disc !important;
    padding-left: 30px;
}

.text-through {
    text-decoration: line-through;
}

.fit-content {
    width: fit-content;
}

.flex1 {
    flex: 1;
}

.pdTop16 {
    padding-top: 16px !important;
}

.pdBot16 {
    padding-bottom: 16px !important;
}

.mrTop16 {
    margin-top: 16px !important;
}

.mrBot16 {
    margin-bottom: 16px !important;
}

.minH49 {
    min-height: 49px !important;
}

.solid-2-blue {
    border-bottom: 2px solid #40a9ff;
}
.solid-2 {
    border-bottom: 1px solid #f0f0f0;
}

.overflow-auto {
    overflow: auto;
    height: auto;
    max-height: 200px;
}

.bg-white-1 {
    background: #fff !important;
}

.bg-black-1 {
    background: #000 !important;
}

.bg-yellow-1 {
    background: #fa8c16 !important;
}

.bg-blue-1 {
    background: #40a9ff !important;
}

.bg-red-1 {
    background: #f5222d !important;
}

.bg-green-1 {
    background: #52d001 !important;
}

.bg-gray-1 {
    background: #f1f1f1 !important;
}
.bg-gray-2 {
    background: #e3e3e3 !important;
}
.bg-gray-3 {
    background: #e1e1e1 !important;
}

.color-primary {
    color: $red-primary;
}
a {
    font-size: 14px;
    color: #262626;
    &:hover {
        color: #262626;
    }
}

.boxShadow {
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}

.w-100 {
    width: 100% !important;
}

.w-50 {
    width: 50% !important;
    @media (max-width: 767px) {
        width: 100% !important;
    }
}

//** custom cho ant design

// ** custom placeholder

::-webkit-input-placeholder {
    color: #cbc4d8;
    font-size: 14px;
}

::-moz-placeholder {
    color: #cbc4d8;
    font-size: 14px;
}

:-ms-input-placeholder {
    color: #cbc4d8;
    font-size: 14px;
}

:-moz-placeholder {
    color: #cbc4d8;
    font-size: 14px;
}

.inputCopy {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-right: 40px;
}

.heartIcon {
    font-size: 20px;
    svg {
        fill: #cbc4d8;
    }
}

.n-focus {
    &:focus {
        box-shadow: none;
    }
}

.text-underline {
    cursor: pointer;
    &:hover {
        text-decoration: underline;
    }
}

.text-underline-1 {
    //cursor: pointer;
    text-decoration: underline;
}

.btnIconSocial {
    border-radius: 50% !important;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px !important;
    height: 40px !important;
}

.border-primary{
    border: 1px solid #eee;
}