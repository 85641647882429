.pageCheckOut2 {
  padding: 50px 0;
  .title{
    font-size: 20px;
    @include breakpoint(xs){
      font-size: 14px;
      margin-bottom: 15px;
    }
  }
  .bookerData{
    margin-top: 30px;
    margin-bottom: 40px;

    .boxBookerData {
      padding: 15px;
      @include breakpoint(xs){
        padding: 0;
        border: none!important;
      }
      .formInput {
        display: flex;
        flex-wrap: wrap;
        margin: 0 -8px;
        .itemInput {
          width: calc(100% / 3) !important;
          padding: 0 8px;
          @include breakpoint(xs){
            width: 50% !important;
            &.w-100{
              width: 100% !important;
            }
          }

        }
      }
    }
  }
  .passengerInfo{
    @include breakpoint(xs){
      border: none;
      padding: 0;
    }
  }
  .listFlight{
    margin-top: 30px;
    padding: 15px;
    @include breakpoint(xs){
      border-color: #D9D9D9;
      background: #F5F5F5;
      border-radius: 4px;
    }
  }
  .boxHotel{
    @include breakpoint(xs){
      .hotelItem{
        border-color: #D9D9D9;
        background: #F5F5F5;
        border-radius: 4px;
      }
    }
  }
  .ant-steps-vertical{
    @include breakpoint(xs){
      flex-direction: inherit;
      .ant-steps-item-tail{
        display: none!important;
      }
      .ant-steps-item-icon{
        float: inherit;
        margin: 0;
      }
      .ant-steps-item{
        width: calc(100% / 3);
        &:first-child, &:last-child{
          width: calc(100% / 3 - 15px);

        }
      }
      .ant-steps-item-title{
        padding-right: 0;
        font-size: 14px;
        color: #595959;
      }
      .ant-steps-item-container{
        text-align: center;
      }
    }
  }
}

.page-content, .pageCheckOut1Content{
  &.open{
    .reviewCombo{
      visibility: visible;
      transform: translateY(0);
    }
  }
}

.br-primary-bold{
  background: #E8E8E8;
  height: 5px;
  width: 100%;
  margin: 20px 0;
}