.reviewCombo {
  padding: 15px;
  @include breakpoint(max-sm) {
    width: 100%;
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    border: none;
    box-shadow: none;
    border-radius: 0;
    margin: 0;
    padding: 15px;
    overflow: hidden;
    transition: 0.4s ease-in-out;
    transform: translateY(100%);
    background: #fff;
    z-index: 100;
    visibility: hidden;
    display: block;
  }
  &Item {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    .p1 {
      color: #595959;
      span.text {
        margin-left: 10px;
      }
    }
    .p2 {
      color: #262626;
      .blue {
        color: #1890ff;
      }
    }
  }
  &Price {
    display: flex;
    justify-content: space-between;
    .p1 {
      font-size: 16px;
      font-weight: 600;
      color: #262626;
    }
    .p2 {
      font-size: 18px;
      font-weight: 600;
      color: #1890ff;
    }
  }
}

.pageCheckOut1 {
  padding: 50px 0;
  @include breakpoint(xs) {
    overflow-x: hidden;
  }
  .flightBasicInfo {
    margin-bottom: 30px;
    @include breakpoint(xs) {
      margin-bottom: 0;
    }
  }
  .listFlight {
    padding: 15px;
    @include breakpoint(xs) {
      padding: 0 0 0px 0;
      border: none !important;
    }
  }
  h2.title {
    font-size: 20px;
    display: flex;
    align-items: center;
    @include breakpoint(xs) {
      font-size: 14px;
      margin-bottom: 15px;
      svg {
        width: 14px;
      }
    }
  }
  p.description {
    @include breakpoint(xs) {
      display: none;
    }
  }

  .hotelInfo {
    @include breakpoint(xs) {
      margin-top: 20px;
    }
  }
  .flightInfo {
    .warning-flight {
      @include breakpoint(xs) {
        display: none;
      }
    }
  }
  .infoCombo {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px;
    @include breakpoint(xs) {
      display: none;
    }
    h2 {
      font-size: 20px;
      font-weight: 400;
    }
    .info {
      .informationDetail {
        display: flex;
        .itemDetail {
          display: flex;
          align-items: center;
          p {
            color: #595959;
            font-size: 14px;
          }
          &:before {
            content: "";
            width: 4px;
            height: 4px;
            border-radius: 50%;
            margin: 0 5px;
            transform: translateY(-1px);
            background: #595959;
          }
          &:first-child {
            &:before {
              display: none;
            }
          }
        }
      }
    }
    .placeInput {
    }
  }
}
.flightItem {
  &Content {
    display: flex;
    justify-content: space-between;
  }
  &Info {
    display: flex;
    align-items: center;
    .flightInfo {
      margin-right: 50px;
      @include breakpoint(xs) {
        display: none;
      }
    }
    .flightTrip {
      display: flex;
      align-items: center;
      @include breakpoint(xs) {
        display: none;
      }
    }
    .flightTripMobile {
      display: none;
      @include breakpoint(xs) {
        display: block;
      }
    }
    .priceUpgrade {
      @include breakpoint(xs) {
        display: none;
      }
    }
    .flightTripInfo {
      display: flex;
      align-items: center;
      .item {
        display: flex;
        align-items: center;
        &:before {
          content: "";
          width: 4px;
          height: 4px;
          border-radius: 50%;
          margin: 0 5px;
          transform: translateY(1px);
          background: #c4c4c4;
        }
        &:first-child {
          &:before {
            display: none;
          }
        }
      }
    }
  }

  &Detail {
    display: none;
  }
  .flightBtn {
    .btnChange,
    .btnDetail {
      display: flex;
      justify-content: flex-end;
    }
  }
  &Logo {
    width: 50px;
    margin-right: 10px;
    @include breakpoint(xs) {
      width: 30px;
      margin-right: 0;
    }
    img {
      width: 100%;
      object-fit: cover;
    }
  }
}
.br-primary {
  background: #d9d9d9;
  width: 100%;
  height: 1px;
  margin: 12px 0;
}

.flightTicket {
  margin-bottom: 16px;
  border-radius: 4px;
  border: 1px solid #d9d9d9;
  &__time {
    border-bottom: 1px solid rgba(255, 255, 255, 0.5);
    margin-bottom: 21px;

    span {
      color: #fff;
      font-size: 16px;
      font-weight: 500;
      transform: translateY(11px);
      display: inline-block;
      background-color: #fa8c16;
      padding-right: 10px;
    }
  }
  &__info__item {
    width: calc(100% / 5);
  }

  &__info {
    background-color: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    padding: 12px;
    border-radius: 4px;
    //box-shadow: $shadow;
    @include breakpoint(max-md) {
      //justify-content: space-around;
    }
    @include breakpoint(xs) {
      padding: 10px;
    }
    @include breakpoint(xss) {
      padding: 8px;
    }
    &__item {
      @include breakpoint(xs) {
        width: auto;
      }
    }
    .alert {
      width: 100%;
      margin-bottom: 0;
      margin-top: 10px;
    }
    .info {
      @include breakpoint(xs) {
        display: block;
      }
      .tripTime {
        font-size: $size-16;
      }
      .p2 {
        color: $gray-12;
        font-size: 12px;
      }
    }
  }
  .info__flight {
    display: none;
    @include breakpoint(xs) {
      display: flex;
      p {
        font-size: 12px;
      }
    }
    @include breakpoint(xss) {
      display: flex;
      p {
        font-size: 10px;
      }
    }
  }
  &__logo {
    width: 60px;
    @include breakpoint(xs) {
      width: 50px;
      margin-right: 5px;
    }
    margin-right: 15px;
    img {
      width: 100%;
    }
  }

  &__code {
    width: 75px;
    font-weight: 500;
    font-size: 14px;
    color: #262626;
    text-align: center;
    padding-left: 15px;
  }

  &__seat {
    width: calc(100% - 595px);
    display: flex;
    flex-wrap: wrap;
    padding-right: 15px;

    li {
      padding-bottom: 4px;
      padding-right: 4px;

      &.active {
        .btnSeat {
          background-color: #fff;
          border-color: #40a9ff;
          color: #40a9ff;
        }
      }

      .tooltip {
        &__cont {
          top: 100%;
          z-index: 5;
        }
      }
    }
  }

  &__click {
    width: auto;
    font-size: 14px;
    color: #1890ff;
    font-weight: $semibold;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    //&:hover {
    //  color: $yellow-2;
    //}

    span,
    svg {
      vertical-align: middle;
      font-weight: 400;
    }

    svg {
      transform: translateY(2px);
    }
    span {
      padding-right: 5px;
    }
  }
  &__info__price {
    font-size: 20px;
    color: #1890ff;
    font-weight: $semibold;
    @include breakpoint(xs) {
      display: none;
    }
  }
  &__info__duration {
    @include breakpoint(xs) {
      display: none;
    }
  }
  .flightTripInfo {
    display: flex;
    align-items: center;
    .item {
      display: flex;
      align-items: center;
      &:before {
        content: "";
        width: 4px;
        height: 4px;
        border-radius: 50%;
        margin: 0 5px;
        transform: translateY(1px);
        background: #c4c4c4;
      }
      &:first-child {
        &:before {
          display: none;
        }
      }
    }
  }
  .flightTrip {
    display: none;
    width: auto;
    padding: 0;
    @include breakpoint(xs) {
      display: block;
    }
  }

  &__btn {
    padding-left: 15px;
    @include breakpoint(xs) {
      width: 120px;
    }
    @include breakpoint(xss) {
      width: 80px;
      padding-left: 0;
    }
    button {
      padding: 4px 10px;
    }
    .price {
      display: none;
      @include breakpoint(xs) {
        display: block;
      }
      color: $blue-1;
      font-size: 20px;
      font-weight: $semibold;
      span {
        font-size: 13px;
        color: $gray-5;
        font-weight: $semibold;
      }
      &.mobile {
        display: none;
      }
      @include breakpoint(xs) {
        font-size: 16px;
        &.mobile {
          display: block;
        }
      }
    }
    .btn {
      width: 100%;
      //@include breakpoint(xs){
      //  width: 50%;
      //}
    }
    .selectFlight {
      text-align: right;
      button {
        width: 100%;
        padding: 4px 10px;
      }
    }
  }

  &__detail {
    background-color: #fff;
    border-top: 1px solid #d2d2d2;
    padding: 15px;
    position: relative;
    animation: opacityShow 0.3s ease-in-out;
    display: none;
    @include breakpoint(max-md) {
      padding: 15px;
    }
  }

  &__list {
    margin-bottom: 24px;
  }

  &__trip {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 24px 16px;
    border: 1px solid $border-color;
    border-radius: 4px;
    margin-bottom: 12px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__policy {
    margin-bottom: 15px;
    &:last-child {
      .br-primary-bold {
        display: none;
      }
    }
    .br-primary-bold {
      margin: 30px 0 !important;
    }
    p {
      &.pTitle {
        font-size: 14px;
        font-weight: 500;
        color: #262626;

        span {
          padding-left: 95px;
        }
      }
      &.pTitle1 {
        font-size: 16px;
        font-weight: 600;
        color: #262626;

        span {
          padding-left: 95px;
        }
      }

      &.pLine {
        display: flex;
        font-size: 14px;
        color: #262626;
        white-space: pre-line;
        margin-bottom: 10px;

        span {
          &:first-child {
            width: 200px;
            @include breakpoint(xs) {
              width: 100px;
            }
          }

          &:last-child {
            width: calc(100% - 200px);
            padding-left: 10px;
            @include breakpoint(xs) {
              width: calc(100% - 100px);
            }
          }
        }
      }
    }
    .boxFlightDetailItem {
      padding: 12px;
      background: #f5f5f5;
      border-radius: 4px;
    }
  }

  &__table {
    .table {
      margin: 16px 0 10px;
    }

    .textNote {
      justify-content: flex-end;
    }
  }

  &__airline {
    display: flex;
    width: calc(35%);
    @include breakpoint(xs) {
      display: none;
    }
    p {
      margin-bottom: 0;
      font-size: 14px;
    }
  }

  &__price {
    p {
      margin-bottom: 0;

      &.p1 {
        font-size: 16px;
        color: #8c8c8c;
        text-decoration: line-through;
      }

      &.p2 {
        font-size: 20px;
        font-weight: $medium;
        color: #262626;
      }
    }
  }

  &.open {
    .flightTicket {
      &__detail {
        display: block;
      }

      &__click {
        svg {
          transform: rotate(180deg);
        }
      }
    }
  }
}
.flightTrip {
  width: 280px;
  display: flex;
  align-items: center;
  padding: 0 15px;

  .d-block {
    text-align: center;

    p {
      margin-bottom: 0;

      &.p1 {
        font-size: 24px;
        color: #262626;
        font-weight: 500;
      }

      &.p2 {
        font-size: 12px;
      }
    }
  }

  .d-svg {
    padding: 0 10px;
    text-align: center;

    svg {
      display: block;
      margin: auto;
    }

    p {
      margin-bottom: 0;
      color: #8c8c8c;
      font-weight: 500;
      font-size: 12px;
    }
  }

  &_lg {
    width: auto;
    padding: 0;

    .d-block {
      text-align: left;

      p {
        &.p1 {
          font-size: 20px;
          @include breakpoint(xs) {
            font-size: 16px;
          }
          @include breakpoint(xss) {
            font-size: 16px;
          }
        }

        &.p2 {
          font-size: 12px;
          @include breakpoint(xss) {
            font-size: 12px;
          }
        }
      }
    }
  }
}

.flightPolicyAnimated {
  &__line {
    height: 8px;
    border-radius: 16px;
    margin-bottom: 10px;
  }

  &__line1 {
    width: 20%;
  }

  &__line2 {
    width: 30%;
  }

  &__line3 {
    width: 40%;
  }

  &__line4 {
    width: 50%;
  }

  &__line5 {
    width: 60%;
  }
}

.flightPolicyAnimated__line {
  height: 8px;
  border-radius: 16px;
  margin-bottom: 10px;
}
.flightPolicyAnimated__line1 {
  width: 20%;
}
.flightPolicyAnimated__line2 {
  width: 30%;
}
.flightPolicyAnimated__line3 {
  width: 40%;
}
.flightPolicyAnimated__line4 {
  width: 50%;
}
.flightPolicyAnimated__line5 {
  width: 60%;
}

.modal-flight-detail {
  .flightTicket__detail {
    display: block;
    border: none;
  }
}

.formSearch {
  .suggestDefault {
    width: 400px;
  }
  .container {
    @include breakpoint(xs) {
      padding: 0;
    }
  }
  .ant-drawer-header {
    @include breakpoint(xs) {
      box-shadow: $shadow;
      border: none;
    }
  }
  .ant-drawer-body {
    @include breakpoint(xs) {
    }
  }
  .formInput {
    @include breakpoint(xs) {
      display: block;
    }
  }
  .inputItem {
    @include breakpoint(xs) {
      width: 100% !important;
    }
  }
  .ant-input-affix-wrapper,
  .btnPassenger,
  .btnDate {
    @include breakpoint(xs) {
      height: 48px !important;
    }
  }
  .ant-drawer-content-wrapper {
    @include breakpoint(xs) {
      height: 100% !important;
    }
  }
  .flightDefault__list {
    display: flex;
    flex-wrap: wrap;
    padding: 5px 0;
    li {
      width: 50%;
      button {
        padding: 10px 15px;
        width: 100%;
        text-align: left;
        transition: all 0.2s;
        &:hover {
          color: #ff8917;
        }
      }
    }
  }
  .btnDate {
    height: 56px;
    width: 100%;
    text-align: left;
  }
  .btnSearch {
    @include breakpoint(xs) {
      button {
        width: 100%;
      }
    }
  }
  .btnPassenger {
    text-align: left;
    p {
      width: 100%;
      white-space: pre-wrap;
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      display: -webkit-box;
    }
  }
}

/// INPUT search
.suggestDefault {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 10;
  width: 100%;
  display: none;
  background-color: #fff;
  box-shadow: $shadow;
  -webkit-animation: opacityShow 0.3s;
  -moz-animation: opacityShow 0.3s;
  animation: opacityShow 0.3s;
  border-radius: 4px;
  overflow: hidden;
  &.open {
    display: block;
  }
  &__header {
    display: none;
  }
  &__body {
    max-height: 418px;
    overflow-y: auto;
  }
}

.hotelItem {
  padding: 12px;
  .hotelImage {
    margin-right: 16px;
    width: 150px;
    min-height: 105px;
    img {
      border-radius: 4px;
      height: 100%;
      object-fit: cover;
      width: 100%;
    }
  }
  .hotelName {
    font-size: $size-16;
    font-weight: $semibold;
  }
  .groupBtn {
    .btnChange,
    .btnDetail {
      display: flex;
      justify-content: flex-end;
    }
  }
  .hotelContent {
    width: calc(100% - 150px);
  }
  @include breakpoint(xs) {
    .hotelImage {
      width: 85px;
      height: 96px;
    }
    .hotelName {
      font-size: 14px;
    }
    .ant-rate-star-first {
      font-size: 14px;
    }
    .address {
      font-size: 12px;
    }
    .hotelContent {
      width: calc(100% - 85px);
    }
  }
  &Selected {
    @include breakpoint(xs) {
      padding: 0;
      border: none !important;
      display: block !important;
      .hotelImage {
        width: 100%;
        min-height: 160px;
        margin-bottom: 15px;
      }
      .hotelContent {
        width: 100%;
      }
      .hotelName {
        font-size: 14px;
      }
      .address {
        margin-top: 5px;
        p {
          font-size: 12px;
        }
      }
    }
  }
}

// Modal
.ant-modal.modal-map {
  margin-top: 0;
  max-width: 100%;
}

.br-mobile {
  background: #f5f5f5;
  height: 5px;
  width: 100%;
  margin: 15px 0;
  display: none;
  @include breakpoint(xs) {
    display: block;
  }
}

.drawer-flight {
  .info {
    @include breakpoint(xs) {
      display: none;
    }
  }
  .ant-drawer-body {
    @include breakpoint(xs) {
      padding: 20px 12px;
    }
  }
  .titleDesktop {
    @include breakpoint(xs) {
      display: none;
    }
  }
  .titleMobile {
    display: none;

    @include breakpoint(xs) {
      display: block;
      text-align: center;
    }
  }
}
.drawer-hotel {
  .ant-drawer-body {
    @include breakpoint(xs) {
      padding: 20px 12px;
    }
  }
}
.infoComboMobile {
  display: none;
  @include breakpoint(xs) {
    position: fixed;
    top: 0;
    left: 0;
    box-shadow: $shadow;
    background: #fff;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px 15px;
    z-index: 100;
    .left {
      display: flex;
      align-items: center;
    }
    .btnChangeInfo {
      display: flex;
      align-items: center;
      color: #1890ff;
      svg {
        margin-right: 5px;
      }
    }
  }
}

.modal-hotelDetail {
  z-index: 1005;
  @include breakpoint(max-sm) {
    width: 80% !important;
    .ant-modal-body {
      padding: 0;
    }
    .image-gallery-slide {
    }
    .image-gallery-slide.center {
      height: 300px;
    }
    .image-gallery-content .image-gallery-slide .image-gallery-image {
      max-height: inherit;
    }
  }
  @include breakpoint(xs) {
    margin: 0 !important;
    width: 100% !important;
  }
}

.hotelDetail {
  display: flex;
  @include breakpoint(max-sm) {
    display: block;
  }
  .hotelGallery {
    width: 50%;
    @include breakpoint(max-sm) {
      width: 100%;
    }
  }
  .hotelServ {
    padding-left: 20px;
    width: 50%;
    @include breakpoint(max-sm) {
      width: 100%;
      padding: 15px;
    }
    &__item {
      margin-bottom: 15px;
    }
    &__title {
      font-size: 16px;
      font-weight: $semibold;
      color: #262626;
    }
    &__cont {
      .pText {
        max-height: 126px;
        overflow: hidden;
        position: relative;
        &:before {
          content: "";
          width: 100%;
          height: 40%;
          position: absolute;
          bottom: 0;
          left: 0;
          background-image: -webkit-linear-gradient(
            top,
            hsla(0, 0%, 100%, 0),
            #fff
          );
          background-image: -moz-linear-gradient(
            top,
            hsla(0, 0%, 100%, 0),
            #fff
          );
          background-image: linear-gradient(180deg, hsla(0, 0%, 100%, 0), #fff);
        }
        &.pText_full {
          max-height: none;
          &:before {
            display: none;
          }
        }
      }
      .btnMore {
        color: #ff8917;
      }
    }
    &__group {
      width: 50%;
      margin-bottom: 10px;
      .pTitle {
        font-size: 14px;
        font-weight: 600;
        display: -moz-box;
        color: #262626;
        display: flex;
        -moz-box-align: center;
        align-items: center;
        margin-bottom: 10px;
      }
    }
    &__flex {
      display: flex;
      flex-wrap: wrap;
      max-height: 200px;
      overflow: hidden;
      position: relative;
      &:before {
        content: "";
        width: 100%;
        height: 40%;
        position: absolute;
        bottom: 0;
        left: 0;
        background-image: -webkit-linear-gradient(
          top,
          hsla(0, 0%, 100%, 0),
          #fff
        );
        background-image: -moz-linear-gradient(top, hsla(0, 0%, 100%, 0), #fff);
        background-image: linear-gradient(180deg, hsla(0, 0%, 100%, 0), #fff);
      }
      &.hotelServ__flex_full {
        max-height: none;
        &:before {
          display: none;
        }
      }
    }
  }
}

.loadingComponent {
  width: 100%;
  padding: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    height: 150px;
  }
}

@keyframes airplane {
  from {
    transform: translateY(0px);
  }
  to {
    //transform: translateY(50px);
    transform: translateY(30px);
    @include breakpoint(xs){
      transform: translateY(20px);
    }
  }
}
@keyframes clouds {
  from {
    right: 0;
  }
  to {
    right: 100%;
  }
}

.flightHoldPopup__img {
  text-align: center;
  height: 100%;
  width: 100%;
  position: relative;
}
.flightHoldPopup__img svg.air-plane {
  width: 150px;
  margin: auto;
  animation: airplane 2s ease infinite alternate;
  position: relative;
  z-index: 1;
}
.flightHoldPopup__img svg.cloud {
  position: absolute;
  right: 0;
}
.flightHoldPopup__img svg.cloud1 {
  top: 25%;
  transform: translate(130%, -25%);
  animation: clouds 8s ease infinite;
  width: 90px;
}
.flightHoldPopup__img svg.cloud2 {
  top: 50%;
  transform: translate(130%, -50%);
  animation: clouds 8s ease infinite 5s;
  width: 50px;
}
.flightHoldPopup__img svg.cloud3 {
  top: 75%;
  transform: translate(130%, -75%);
  animation: clouds 8s ease infinite 3s;
  width: 70px;
}
