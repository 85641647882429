$yellow-2: #1890ff;
.pagination{
  display: flex;
  justify-content: center;
  padding: 25px 15px;
  &__item{
    + .pagination__item{
      padding-left: 8px;
    }
    &.active{
      .pagination__link{
        background: #ff6e1e1a;
        .text{
          background: linear-gradient(93.2deg, #ffc204 -32.19%, #ff057d 125.11%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }
      }
    }
    &.disabled{
      cursor: not-allowed;
      .pagination__link{
        pointer-events: none;
        opacity: .4;
      }
    }
    &.paginationPage{
      .pagination__link{
        padding: 0 15px;
      }
    }
  }
  &__link{
    min-width: 40px;
    height: 40px;
    width: 40px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    background: transparent;
    font-size: 16px;
    transition: .3s;
    color: #595959;
    &__prev, &__next{
      background: hsla(0,0%,100%,.1);
    }
    .text{
      color: white;
    }

    &:hover{
      color: $yellow-2;
      border-color: $yellow-2;
    }
    svg{
      width:50%;
    }
  }
}