.modalDateFlight {
  @include breakpoint(xs) {
    margin-top: 0 !important;
  }
  .ant-modal-body,
  .ant-modal-content {
    @include breakpoint(xs) {
      height: 100%;
    }
  }

  .ant-modal-content {
    @include breakpoint(xs) {
      overflow: hidden;
    }
  }
  .ant-modal-header {
    display: none;
    @include breakpoint(xs) {
      display: block;
    }
  }
  .ant-modal-close {
    display: none;
    @include breakpoint(xs) {
      left: 0;
      display: block;
    }
  }
  .comboIno,
  .content {
    //display: none;
    @include breakpoint(xs) {
      display: block;
    }
  }
  .btn-search {
    @include breakpoint(xs) {
      display: flex;
      position: absolute;
      width: 100%;
      justify-content: center !important;
      left: 0;
      bottom: 15px;
      button {
        width: calc(100% - 30px);
      }
    }
  }
  .btn-cancel {
    height: 40px;
    text-shadow: inherit;
    @include breakpoint(xs) {
      display: none;
    }
  }
  .ant-modal-header {
    @include breakpoint(xs) {
      border: none;
      box-shadow: $shadow;
      padding: 10px;
      .step3 {
        .note {
          display: none;
        }
      }
    }
  }
  .DayPicker-Day--1999 {
    span {
      color: #52c41a;
    }
  }
  .DayPicker-Day{
    height: 50px;
    @include breakpoint(xs){
      height: 35px;
    }
  }
  .footerFlight{
    display: flex;
    align-items: center;
    justify-content: space-between;
    @include breakpoint(xs){
      display: block;
      p{
        width: 100%;
        text-align: center;
      }
    }
  }
}

.pageMaintenance{
  padding: 50px 0 150px 0;
  display: flex;
  justify-content: center;
  @include breakpoint(xs) {
    padding: 50px 0;

  }
  &Content{
    text-align: center;
    img{
      width: 600px;
      height: 100%;
      object-fit: cover;
      @include breakpoint(xs) {
        width: 100%;
      }
    }
  }
}